import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LinearProgress,
  linearProgressClasses,
  Stack,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 13,
  borderRadius: 5,
}))
export const SkillProgress: FC<{
  label: string
  value: number
  years: number | string
  linearProgressColor?: string
  linearProgressColorBg?: string
}> = memo(
  ({ label, value, years, linearProgressColor, linearProgressColorBg }) => {
    const { t } = useTranslation()
    const progressLabel = useMemo((): string => {
      const validYears = typeof years === 'string' ? parseFloat(parseFloat(years).toFixed(1)) : years

      if (validYears === 0) {
        return t('organisms.candidateOnePager.lessThan1Yr')
      }

      if (validYears > 0 && validYears < 1) {
        const months = Math.round(validYears * 12)
        return `${months} ${
          months === 1
            ? t('organisms.candidateOnePager.month')
            : t('organisms.candidateOnePager.months')
        }`
      }

      return validYears === 1
        ? `${validYears} ${t('organisms.candidateOnePager.year')}`
        : `${validYears} ${t('organisms.candidateOnePager.years')}`
    }, [t, years])

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
        gap={2}
      >
        <Typography sx={{ flex: 3 }} variant="body2">
          {label}
        </Typography>
        <Tooltip title={progressLabel}>
          <BorderLinearProgress
            sx={{
              flex: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor:
                  linearProgressColorBg ?? 'onePager.skillsProgressBg',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor:
                  linearProgressColor ?? 'onePager.skillsProgress',
              },
            }}
            variant="determinate"
            value={value}
          />
        </Tooltip>
      </Stack>
    )
  }
)
