import {
  BaseSubjectStore,
  ICart,
  ICollaborator,
  IEmailPayload,
} from '@procom-labs/common'

import { ISubmissionSummary } from '@submission-portal/models'
import {
  DefaultSubmissionSearchFilter,
  DisplayModes,
  SubmissionPrepSearchFilter,
} from '@submission-portal/types'

export interface ISubmissionStoreState {
  count: number
  submissions: ISubmissionSummary[]
  collaborators: ICollaborator[]
  cart: ICart | null
  loading: boolean
  searchFilter: SubmissionPrepSearchFilter
  isSearchOpen: boolean
  isFilterModalOpen: boolean
  displayMode: DisplayModes
  isInsightsModalOpen: boolean
  isMessageModalOpen: boolean
  isCandidateCartOpen: boolean
  isCandidateRemoveModalOpen: boolean
  touchedCandidate: string | null
  isSaveConfirmationModalOpen: boolean
  unsavedChanges: boolean
  emailObject: IEmailPayload | null
  selectedSubmissionId?: string | null
  isTogglingCandidateHighlight: boolean
  clientActivities: string[]
}

export class SubmissionStore extends BaseSubjectStore<ISubmissionStoreState> {
  getDefaultValue(): Required<ISubmissionStoreState> {
    return {
      submissions: [],
      collaborators: [],
      cart: null,
      count: 0,
      loading: true,
      searchFilter: DefaultSubmissionSearchFilter,
      isSearchOpen: false,
      displayMode: DisplayModes.Card,
      isFilterModalOpen: false,
      isCandidateCartOpen: false,
      isMessageModalOpen: false,
      isInsightsModalOpen: false,
      isCandidateRemoveModalOpen: false,
      touchedCandidate: null,
      isSaveConfirmationModalOpen: false,
      unsavedChanges: false,
      emailObject: null,
      selectedSubmissionId: null,
      isTogglingCandidateHighlight: false,
      clientActivities: [],
    }
  }

  setClientActivities(submissions: ISubmissionSummary[]): void {
    const activities = [...this.state.clientActivities.value]
    submissions.forEach((submission) => {
      if (submission.status && !activities.includes(submission.status)) {
        activities.push(submission.status)
      }
    })
    this.dispatch({
      clientActivities: activities,
    })
  }

  get candidateHighlightToggleStatus(): boolean {
    return this.state.isTogglingCandidateHighlight.value
  }
}

export const submissionStore = new SubmissionStore()
