import React, { FC, useMemo } from 'react'

import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  getInitials,
  getRandomColor,
  Name,
  OnePagerModes,
} from '@procom-labs/common'

import { EditableLabel } from './form'

export const OnePagerHeadline: FC<{
  name: Name
  candidateTitle?: string | null
  currentView?: OnePagerModes
}> = ({ name: { firstName, lastName }, candidateTitle, currentView }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  const handleGetInitials = useMemo(() => {
    return getInitials(`${firstName} ${lastName}`, 2)
  }, [firstName, lastName])

  const avatarColor = useMemo(() => getRandomColor(), [])

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Avatar
        src=""
        alt={`${firstName}-${lastName}`}
        sx={{
          backgroundColor: `#${avatarColor}`,
          width: 48,
          height: 48,
          fontWeight: 500,
          fontSize: 20,
          lineHeight: 24,
          color: (innerTheme) => innerTheme.palette.common.white,
        }}
      >
        {`${handleGetInitials}` || (
          <PersonIcon sx={{ width: 48, height: 48 }} />
        )}
      </Avatar>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
        gap="6px"
      >
        <Typography variant="h5" component="span" fontWeight={700}>
          {firstName} {lastName}
        </Typography>
        {currentView === OnePagerModes.VIEW && candidateTitle && (
          <Typography variant="h5">| {candidateTitle}</Typography>
        )}
        {currentView === OnePagerModes.EDIT && (
          <>
            <Typography variant="h5">| </Typography>
            <EditableLabel
              name="candidateTitle"
              typographyVariant="h5"
              inputFontSize={theme.typography.h5.fontSize}
            />
          </>
        )}
      </Box>
    </Stack>
  )
}
