import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { debounceTime, Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {
  alpha,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'
import { Preloader } from '@procom-labs/atoms'
import {
  errorToString,
  getInitials,
  ICartCandidate,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { SubmissionUpdateForm } from '@submission-portal/features/submission-detail/components/submission-update/submission-update-form'
import { usePrompt } from '@submission-portal/hooks'
import { rollbarInstance } from '@submission-portal/providers'
import { jobAiService, submissionService } from '@submission-portal/services'

export const CandidateCartPrepForm: FC<{
  candidate: ICartCandidate
  nextHandler: () => void
  backHandler: () => void
  handleBackToList: () => void
  enableBack: boolean
}> = ({
  candidate,
  nextHandler,
  backHandler,
  enableBack,
  handleBackToList,
}) => {
  const { t } = useTranslation('main')

  const [isLoading, setIsLoading] = useState(true)
  const [canNavigate, setCanNavigate] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { addAlert } = useAlert()

  const theme = useTheme()
  const emailSubmissionFormRef = useRef<any>()
  const generateCandidateStreamRef = useSubscriptionRef()

  const submission = useObservable(() => submissionService.currentSubmission$)
  const submissionUpdateFormProps = useObservable(() =>
    submissionService.submissionUpdateFormPropSubject.observable$.pipe(
      debounceTime(300)
    )
  )
  usePrompt(
    t('submissionDetail.candidateDetails.alert.leave'),
    canNavigate ? false : submissionUpdateFormProps?.dirty || false
  )
  const hasResume: boolean = useMemo(
    () => !!submission?.candidate.resume?.fileStorageId,
    [submission?.candidate.resume]
  )

  const next = useCallback((): void => {
    if (submissionUpdateFormProps) {
      const { isValid, dirty, values, submitForm, setSubmitting } =
        submissionUpdateFormProps

      if (isValid && hasResume) {
        if (dirty) {
          if (values && setSubmitting) {
            setSubmitting(true)
            setCanNavigate(false)

            const savedTemplate =
              submission?.candidate.submissionEmailTemplate?.emailTemplates.find(
                (template) => template.language === values.submissionLanguage
              )

            submissionService
              .updateSubmission(values, !!savedTemplate)
              .subscribe({
                complete: () => {
                  setSubmitting(false)
                  setCanNavigate(true)
                  addAlert({
                    message: t('submissionDetail.candidateDetails.alert.save'),
                  })
                  nextHandler()
                },
                error: () => {
                  setSubmitting(false)
                  addAlert({
                    severity: 'error',
                    message: t(
                      'submissionDetail.candidateDetails.alert.failed'
                    ),
                  })
                },
              })
          }
        } else {
          setCanNavigate(true)
          nextHandler()
        }
      } else if (submitForm && setSubmitting) {
        submitForm()
        setSubmitting(false)
        rollbarInstance.error('Please complete missing fields.')
        addAlert({
          severity: 'error',
          message: t('submissionDetail.candidateDetails.alert.missingField'),
        })
      }
    }
  }, [
    addAlert,
    hasResume,
    submissionUpdateFormProps,
    submission?.candidate.submissionEmailTemplate?.emailTemplates,
    t,
    nextHandler,
  ])
  useEffect(() => {
    generateCandidateStreamRef.current = jobAiService
      .getCandidateHighlightsStream()
      .subscribe()
  }, [generateCandidateStreamRef])

  useEffect(() => {
    const subscription = new Subscription()
    if (candidate.atsJobSubmissionId) {
      setIsLoading(true)
      subscription.add(
        submissionService
          .getSubmissionDetail(+candidate.atsJobSubmissionId)
          .subscribe({
            next: () => setIsLoading(false),
            error: (err) => {
              setError(errorToString(err))
              setIsLoading(false)
            },
          })
      )
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [candidate.atsJobSubmissionId])

  if (isLoading) {
    return <Preloader center />
  }

  if (!submission)
    return (
      <div className="center-screen">
        <Typography paragraph variant="h3">
          {t('error.notFound')}
        </Typography>
        {error && (
          <Typography variant="h5" paragraph>
            {error}
          </Typography>
        )}
      </div>
    )

  return (
    <Box>
      <Grid container flexWrap="nowrap">
        <Grid item xs={1} sx={{ minWidth: 'fit-content', my: 'auto', pr: 2 }}>
          <Tooltip
            title={t('submissionDetail.candidateDetails.tooltip.backToList')}
          >
            <IconButton onClick={handleBackToList}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          container
          gap={2}
          key={submission.atsJobSubmissionId}
          xs={11}
          sx={{
            p: '10px 18px',
            borderRadius: '4px',
            backgroundColor: alpha(theme.palette.info.light, 0.2),
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
            }}
          >
            <Avatar
              src=""
              alt={`${submission.candidate.firstName}-${submission.candidate.lastName}`}
              sx={{
                backgroundColor: candidate.backgroundColor
                  ? `#${candidate.backgroundColor}`
                  : `${theme.palette.common.black}`,
                width: 44,
                height: 44,
                fontWeight: 400,
                fontSize: 20,
                lineHeight: 20,
                color: (innerTheme) => innerTheme.palette.common.white,
              }}
            >
              {getInitials(
                `${candidate.firstName} ${candidate.lastName}`,
                2
              ) || <PersonIcon sx={{ width: 35, height: 35 }} />}
            </Avatar>
            <Typography sx={{ ml: '12px' }}>
              {`${candidate.firstName} ${candidate.lastName}`}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
            }}
          >
            <EmailOutlinedIcon fontSize="small" sx={{ mr: 1, mt: '2px' }} />
            <Typography>{candidate.email}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <SubmissionUpdateForm
        submission={submission}
        nextHandler={next}
        ref={emailSubmissionFormRef}
        backHandler={enableBack ? backHandler : undefined}
        isCartSubmission
        hideButtons
      />
    </Box>
  )
}
