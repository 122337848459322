import { concatMap, map, mergeMap, Observable, of, Subscription } from 'rxjs'
import * as Yup from 'yup'

import {
  CandidateAtsService,
  EmploymentTypes,
  ICandidate,
  IContractorProfileResume,
  IDocument,
  IResume,
  JobSubmissionAtsStatusTypeMap,
  JobSubmissionPortalStatusTypeMap,
  SortOrder,
} from '@procom-labs/common'

import { environment } from '@submission-portal/environment'
import {
  AtsResourcesFetcherReturn,
  fetchContractorProfileResources,
} from '@submission-portal/hooks'
import { ISubmissionSummary } from '@submission-portal/models'
import { submissionService } from '@submission-portal/services'
import { SubmissionStore } from '@submission-portal/stores'
import { CandidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import {
  CandidatesPrepSearchFilter,
  ICandidatePrepFormValues,
  IDetailedSubmission,
  SubmissionFields,
} from '@submission-portal/types'

export const CandidatePrepFilterValidationSchema = Yup.object().shape({
  // Empty for next implementation
})
const SortFieldMap: Partial<
  Record<SubmissionFields, keyof ISubmissionSummary>
> = {
  [SubmissionFields.Candidate]: 'firstName',
  [SubmissionFields.DateAdded]: 'dateAddedOnAts',
  [SubmissionFields.LastModified]: 'dateLastModified',
  [SubmissionFields.AddedBy]: 'addedBy',
  [SubmissionFields.Status]: 'status',
}

export const sortCandidates = (
  rows: ISubmissionSummary[],
  key: keyof ISubmissionSummary | undefined,
  sortOrder: SortOrder
): ISubmissionSummary[] => {
  const candidatePrepComparator = (
    a: ISubmissionSummary,
    b: ISubmissionSummary
  ): number => {
    if (key === undefined) {
      return 0
    }

    const aValue = a[key]
    const bValue = b[key]

    if (aValue === null || bValue === null) {
      return 0
    }

    if (aValue < bValue) return sortOrder === SortOrder.asc ? -1 : 1
    if (aValue > bValue) return sortOrder === SortOrder.asc ? 1 : -1
    return 0
  }

  return [...rows].sort(candidatePrepComparator)
}

export const filterAndSortPrepCandidates = (
  rows: ISubmissionSummary[],
  searchFilter: CandidatesPrepSearchFilter
): [ISubmissionSummary[], number] => {
  const filterByStatus = (
    rowsToFilter: ISubmissionSummary[],
    statusTypeMap: Record<string, string>,
    att: 'status' | 'atsSubmissionStatus'
  ): ISubmissionSummary[] => {
    const allowedStatuses = Object.entries(statusTypeMap)
      .filter(
        ([, value]) => searchFilter[value as keyof CandidatesPrepSearchFilter]
      )
      .map(([key]) => key)

    return allowedStatuses.length
      ? rowsToFilter.filter((row) =>
          allowedStatuses.includes(row[att] as unknown as string)
        )
      : rowsToFilter
  }

  const filteredByPortalStatus = filterByStatus(
    rows,
    JobSubmissionPortalStatusTypeMap,
    'status'
  )
  const filteredByAtsStatus = filterByStatus(
    filteredByPortalStatus,
    JobSubmissionAtsStatusTypeMap,
    'atsSubmissionStatus'
  )

  // Temporary band-aid solution
  const filteredBySyncedSubmission = filteredByAtsStatus.filter(
    (submission) => !!submission.jobSubmissionId
  )
  const sortedRows = sortCandidates(
    environment.ENABLE_NON_SYNCED_CANDIDATES_FILTER
      ? filteredBySyncedSubmission
      : filteredByAtsStatus,
    SortFieldMap[searchFilter.sortField as SubmissionFields],
    searchFilter.sortOrder
  )

  return [sortedRows, sortedRows.length]
}

type FetchResumeAndCoverPageArgs = {
  fetchResource: AtsResourcesFetcherReturn
  candidateStore: CandidatesPrepStore
}

export const fetchResumeAndCoverPage = ({
  fetchResource,
  candidateStore,
}: FetchResumeAndCoverPageArgs): Observable<{
  resume: IResume | null | undefined
  coverPage: IResume | null | undefined
  additionalDocuments: IDocument[] | undefined
}> => {
  return fetchContractorProfileResources({
    fetchResource,
    candidateStore,
  }).pipe(
    map(({ resume, coverPage, additionalDocuments }) => ({
      resume,
      coverPage,
      additionalDocuments,
    }))
  )
}

export const processSubmission = (args: {
  atsJobId: string
  fetchResource: AtsResourcesFetcherReturn
  candidateStore: CandidatesPrepStore
  candidateAtsService: CandidateAtsService
}): Observable<ICandidate | null> => {
  const { atsJobId, candidateStore, fetchResource, candidateAtsService } = args

  const { candidate: submission } = candidateStore.getStateValue()

  if (!submission) {
    return of(null)
  }

  const { isCoverPageUploadEnabled } = submission.candidate

  return fetchResumeAndCoverPage({ fetchResource, candidateStore }).pipe(
    mergeMap(({ resume, coverPage }) => {
      if (!resume?.fileStorageId) {
        return of(null)
      }

      const formattedResumeArgs = {
        atsJobId: parseInt(atsJobId, 10),
        atsCandidateResumeInfo: {
          fileStorageInfo: {
            fileStorageId: resume.fileStorageId,
          },
          extension: (resume as IContractorProfileResume)?.wordFileStorageId
            ? 'docx'
            : 'pdf',
        },
        atsJobCoverPageInfo: coverPage?.fileStorageId
          ? {
              fileStorageInfo: {
                fileStorageId: coverPage.fileStorageId,
              },
              extension: (coverPage as IContractorProfileResume)
                ?.wordFileStorageId
                ? 'docx'
                : 'pdf',
            }
          : undefined,
        addJobCoverPageToSubmissionDocument: isCoverPageUploadEnabled,
        // We will need in the advanced features to allow these options
        // candidateInfo: {
        //   ...candidateInfo,
        //   removePersonalInfo: removePersonalInformation,
        // },
        // resumeTemplateConfiguration: {
        //   templateFileStorageId: templateData.data.fileStorageId,
        //   language: templateData.data.language,
        //   dateFormats: templateData.data.dateFormats,
        // },
      }

      return candidateAtsService.formatResume(formattedResumeArgs).pipe(
        concatMap((fileSfdtText) => {
          return candidateAtsService.postSubmissionFile(
            submission.id,
            JSON.stringify(fileSfdtText)
          )
        }),
        concatMap((formattedSubmissionFile) => {
          return submissionService.updatePrepSubmission({
            ...submission,
            candidate: {
              ...submission.candidate,
              formattedSubmissionFile:
                formattedSubmissionFile as unknown as IResume,
              resume,
              coverPage: coverPage ?? null,
            },
          })
        })
      )
    })
  )
}

export const updateCandidatesAfterSubmission = (args: {
  id: string
  update: ISubmissionSummary
  store: SubmissionStore
}): void => {
  const { id, update, store } = args
  const { submissions: rows } = store.getStateValue()

  const newRows = rows.map((candidate) =>
    candidate.jobSubmissionId === id
      ? {
          ...candidate,
          status: update.status,
          jobSubmissionId: update.atsSubmissionStatus,
        }
      : candidate
  )

  store.dispatch({
    submissions: newRows,
  })
}

type OptionalResume = IResume | null | undefined
export const saveCandidateData = (
  record: IDetailedSubmission,
  data: ICandidatePrepFormValues | undefined,
  {
    resume,
    coverPage,
    additionalDocuments,
  }: {
    resume: OptionalResume
    coverPage: OptionalResume
    additionalDocuments: IDocument[] | undefined
  },
  handleSuccess: () => any,
  doHandleError: () => void
): Subscription => {
  return submissionService
    .updatePrepSubmission({
      ...record,
      candidate: {
        ...record.candidate,
        ...(data && {
          dateAvailable: data.profile.dateAvailable,
          billRate: data.profile.billRate,
          employmentType: data.profile.employmentType as EmploymentTypes,
          // location
          address: data.profile.location.addressLine1 as string,
          city: data.profile.location.city as string,
          state: data.profile.location.state as string,
          countryName: data.profile.location.country as string,
          countryCode: data.profile.location.countryCode as string,
          stateCode: data.profile.location.stateCode as string,
          latitude: data.profile.location.latitude
            ? data.profile.location.latitude
            : null,
          longitude: data.profile.location.longitude
            ? data.profile.location.longitude
            : null,
          zip: data.profile.location.postalCode as string,
          // visibility
          clientPortalFieldsVisibility: data.clientPortalFieldsVisibility,
        }),
        // files
        resume: resume?.fileStorageId ? resume : null,
        coverPage: coverPage ?? null,
        additionalDocuments: additionalDocuments ?? [],
      },
    })
    .subscribe({
      next: () => {
        // If there's a highlight, proceed with the second call. Otherwise, show success immediately.
        if (data?.highlights) {
          submissionService
            .saveCandidateHighlights(record?.id, data.highlights)
            .subscribe({
              next: handleSuccess,
              error: doHandleError,
            })
        } else {
          handleSuccess()
        }
      },
      error: doHandleError,
    })
}
