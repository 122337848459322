import { FC, memo, useMemo } from 'react'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  EmploymentHistory,
  getNormalizedDateString,
  isADate,
} from '@procom-labs/common'

import { StyledAccordion } from '../common'

const getDateString = (date?: string | null): string => {
  if (!date) return ''
  return isADate(date)
    ? getNormalizedDateString(date, { month: 'short', year: 'numeric' })
    : date
}
export const ExperienceAccordion: FC<{
  startDate?: string | null
  endDate?: string | null
  title?: string | null
  subtitle?: string | null
  description?: EmploymentHistory['description']
}> = memo(({ title, subtitle, startDate, endDate, description }) => {
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))

  const startDateString = useMemo(() => getDateString(startDate), [startDate])
  const endDateString = useMemo(() => getDateString(endDate), [endDate])

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={
          description ? <ExpandMoreRoundedIcon fontSize="small" /> : null
        }
        sx={{
          padding: '0',
          minHeight: 0,
          borderBottom: 0,
          '& .MuiAccordionSummary-content': {
            margin: '0',
          },
        }}
      >
        <Grid container gap={2}>
          {(startDate || endDate) && (
            <Grid item xs={isMobileOrTab ? 12 : 2}>
              {startDate && (
                <Typography
                  variant="subtitle1Bold"
                  sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
                >
                  {startDateString}
                </Typography>
              )}
              {startDate && endDate && (
                <Typography
                  variant="subtitle1Bold"
                  sx={{ color: 'text.secondary' }}
                >
                  {' '}
                  -{' '}
                </Typography>
              )}
              {endDate && (
                <Typography
                  variant="subtitle1Bold"
                  sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
                >
                  {endDateString}
                </Typography>
              )}
            </Grid>
          )}

          <Grid item>
            <Typography variant="subtitle1Bold">
              {title}
              <Typography
                component="span"
                variant="subtitle1"
                sx={{ color: theme.palette.text.secondary }}
              >
                {subtitle && `${title ? ' | ' : ''}${subtitle}`}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {description && (
        <AccordionDetails sx={{ px: 0 }}>
          <Grid
            container
            sx={{ flexDirection: isMobileOrTab ? 'row' : 'row-reverse' }}
          >
            <Grid item xs={isMobileOrTab ? 12 : 10} pl={isMobileOrTab ? 0 : 2}>
              <Typography variant="body2" whiteSpace="pre-line">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
    </StyledAccordion>
  )
})
