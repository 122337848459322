import {
  BaseSubjectStore,
  DataObject,
  DataOption,
  ICandidate,
  IContractorProfileResume,
  JobSubmissionAtsStatusLabels,
} from '@procom-labs/common'

import { ISubmissionSummary } from '@submission-portal/models'
import {
  CandidatesPrepSearchFilter,
  CandidatesPrepStep,
  DefaultCandidatesPrepSearchFilter,
  FileModes,
  IDetailedSubmission,
} from '@submission-portal/types'

export interface AdditionalDocument {
  file: IContractorProfileResume | null
  fileBlob: string
}
export interface ICandidatesPrepStoreState {
  candidatesCount: number
  candidate: IDetailedSubmission | null
  candidateStatus: JobSubmissionAtsStatusLabels | null
  candidates: ISubmissionSummary[]
  loading: boolean
  searchFilter: CandidatesPrepSearchFilter
  isAddingCandidate: boolean
  isSearchOpen: boolean
  isFilterModalOpen: boolean
  isModalRejectedOpen: boolean
  isModalRequestInterviewOpen: boolean
  isModalExtendOfferOpen: boolean

  modalCandidateData: Partial<ICandidate>
  modalAtsStatus: string
  accountManagerName: string
  jobTitle: string

  resumeMode: FileModes
  resumeSelections: DataOption<DataObject>[]
  resumeSelection: string
  resumeFile: IContractorProfileResume | null
  resumeFileBlob: string
  resumeSelectionBlob: string

  coverPageMode: FileModes
  coverPageSelections: DataOption<DataObject>[]
  coverPageSelection: string
  coverPageFile: IContractorProfileResume | null
  coverPageFileBlob: string
  coverPageSelectionBlob: string

  additionalDocuments: AdditionalDocument[]

  prepStep: CandidatesPrepStep

  clientSubmissionUnderProcessing: string | null
  processing: boolean
  preppingSubmission: boolean
}

export class CandidatesPrepStore extends BaseSubjectStore<ICandidatesPrepStoreState> {
  getDefaultValue(): Required<ICandidatesPrepStoreState> {
    return {
      candidate: null,
      candidateStatus: null,
      candidates: [],
      candidatesCount: 0,
      loading: true,
      searchFilter: DefaultCandidatesPrepSearchFilter,
      isAddingCandidate: false,
      isSearchOpen: false,
      isFilterModalOpen: false,
      isModalRejectedOpen: false,
      isModalRequestInterviewOpen: false,
      isModalExtendOfferOpen: false,

      modalCandidateData: {},
      modalAtsStatus: '',
      accountManagerName: '',
      jobTitle: '',

      resumeMode: FileModes.Select,
      resumeSelections: [],
      resumeSelection: '',
      resumeFile: null,
      resumeFileBlob: '',
      resumeSelectionBlob: '',

      coverPageMode: FileModes.Select,
      coverPageSelections: [],
      coverPageSelection: '',
      coverPageFile: null,
      coverPageFileBlob: '',
      coverPageSelectionBlob: '',

      additionalDocuments: [
        {
          file: null,
          fileBlob: '',
        },
      ],

      prepStep: CandidatesPrepStep.Upload,
      clientSubmissionUnderProcessing: null,
      processing: false,
      preppingSubmission: false,
    }
  }
}

export const candidatesPrepStore = new CandidatesPrepStore()
