import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { forkJoin } from 'rxjs'
import * as Yup from 'yup'

import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import {
  conditionHandler,
  IDocument,
  ILocation,
  locationValidation,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert, useDefaultErrorHandler } from '@procom-labs/molecules'

import { yupResolver } from '@hookform/resolvers/yup'
import { CandidatePrepCandidateEditorHeader } from '@submission-portal/components/candidate-prep/components/candidate-prep-candidate-editor-header'
import { CandidatePrepCandidateTabContainer } from '@submission-portal/components/candidate-prep/pages/candidate-prep-candidate-tab-container'
import {
  useAtsResourcesFetcher,
  useErrorAndCompletionStoreObserver,
  useScopedFetchFile,
} from '@submission-portal/hooks'
import { submissionService } from '@submission-portal/services'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import {
  CandidatesPrepStep,
  ICandidatePrepFormValues,
  IDetailedSubmission,
} from '@submission-portal/types'

import {
  fetchResumeAndCoverPage,
  saveCandidateData,
} from '../candidate-prep-utils'

const CandidatePrepCandidateEditorContent: React.FC = () => {
  const { id } = useParams()
  const { candidate: submission } = useSubjectSelector(candidatesPrepStore, [
    'candidate',
  ])
  const candidate = submission?.candidate
  const { t } = useTranslation('main')

  const validationSchema = Yup.object().shape({
    clientPortalFieldsVisibility: Yup.object().shape({
      isRateVisible: Yup.boolean(),
      isAvailabilityVisible: Yup.boolean(),
      isLegalStatusVisible: Yup.boolean(),
      isLocationVisible: Yup.boolean(),
    }),
    profile: Yup.object().shape({
      dateAvailable: Yup.string(),
      billRate: Yup.number(),
      employmentType: Yup.string(),
      location: locationValidation,
    }),
    highlights: Yup.string(),
  })

  const defaultValues: ICandidatePrepFormValues = {
    clientPortalFieldsVisibility: {
      isRateVisible: conditionHandler(
        candidate?.clientPortalFieldsVisibility,
        candidate?.clientPortalFieldsVisibility?.isRateVisible,
        false
      ) as boolean,
      isAvailabilityVisible: conditionHandler(
        candidate?.clientPortalFieldsVisibility,
        candidate?.clientPortalFieldsVisibility?.isAvailabilityVisible,
        false
      ) as boolean,
      isLegalStatusVisible: conditionHandler(
        candidate?.clientPortalFieldsVisibility,
        candidate?.clientPortalFieldsVisibility?.isLegalStatusVisible,
        false
      ) as boolean,
      isLocationVisible: conditionHandler(
        candidate?.clientPortalFieldsVisibility,
        candidate?.clientPortalFieldsVisibility?.isLocationVisible,
        false
      ) as boolean,
    },
    profile: {
      dateAvailable: candidate?.dateAvailable ?? '',
      billRate: candidate?.billRate ?? 0,
      employmentType: candidate?.employmentType ?? '',
      location: {
        addressLine1: candidate?.address ?? '',
        city: candidate?.city ?? '',
        state: candidate?.state ?? '',
        country: candidate?.countryName ?? '',
        countryCode: candidate?.countryCode ?? '',
        stateCode: candidate?.stateCode ?? '',
        latitude: candidate?.latitude ?? '',
        longitude: candidate?.longitude ?? '',
        postalCode: candidate?.zip,
      } as ILocation,
    },
    highlights: submission?.highlights ?? '',
  }

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const { addAlert } = useAlert()

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const subscriptionRef = useSubscriptionRef()
  const onFetchFile = useScopedFetchFile()
  const fetchResource = useAtsResourcesFetcher(onFetchFile)
  const doHandleError = useCallback((): void => {
    candidatesPrepStore.dispatch({
      processing: false,
    })
    handleError()
  }, [handleError])

  const handleSuccess = useCallback((): void => {
    candidatesPrepStore.dispatch({
      processing: false,
    })
    addAlert({
      severity: 'success',
      message: t('common.alert.itemSaved'),
    })
  }, [addAlert, t])

  const handleSubmission = useCallback(
    (data: ICandidatePrepFormValues, record: IDetailedSubmission): void => {
      candidatesPrepStore.dispatch({
        processing: true,
      })

      subscriptionRef.current = fetchResumeAndCoverPage({
        fetchResource,
        candidateStore: candidatesPrepStore,
      }).subscribe({
        next: (files) => {
          // Clear any previous subscription if active
          if (subscriptionRef.current && !subscriptionRef.current.closed) {
            subscriptionRef.current.unsubscribe()
          }

          subscriptionRef.current = saveCandidateData(
            record,
            data,
            files,
            handleSuccess,
            handleError
          )
        },
        error: doHandleError,
      })
    },
    [handleError, doHandleError, fetchResource, handleSuccess, subscriptionRef]
  )

  // This must be moved to a separate hook
  const onSubmit = useCallback(
    (data: any, event: any): void => {
      const submitterId = event.nativeEvent?.submitter?.id
      if (submitterId !== 'btn-submit-candidate-prep') {
        // This a hack as when we hit the generate highlight button, this method will be called
        // as it's a submit button. At the same time, we do not want to have auto save behaviour here.
        // The proper solution is to update the generation editor to give more control
        // over the generate button
        return
      }

      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
      }

      if (submission) {
        handleSubmission(data, submission)
      }
    },
    [subscriptionRef, submission, handleSubmission]
  )

  // Don't render anything if there is no id param
  if (!id) return null

  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <CandidatePrepCandidateEditorHeader />
          <Box sx={{ mt: '3rem' }}>
            <CandidatePrepCandidateTabContainer />
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export const CandidatePrepCandidateEditor: React.FC = () => {
  const subscriptionRef = useSubscriptionRef()
  const { id } = useParams()

  const { candidate: submission, loading } = useSubjectSelector(
    candidatesPrepStore,
    ['candidate', 'loading']
  )

  const handleSubmission = useCallback(([record, status]) => {
    candidatesPrepStore.dispatch({
      candidate: record,
      candidateStatus: status,
      additionalDocuments: record.candidate.additionalDocuments.length
        ? record.candidate.additionalDocuments.map((document: IDocument) => ({
            file: document,
            fileBlob: '',
          }))
        : [{ file: null, fileBlob: '' }],
      prepStep: record.candidate.formattedSubmissionFile
        ? CandidatesPrepStep.Prep
        : CandidatesPrepStep.Upload,
    })
  }, [])

  const errorAndCompletionObserver = useErrorAndCompletionStoreObserver({
    store: candidatesPrepStore,
    next: handleSubmission,
  })

  useEffect(() => {
    if (id) {
      candidatesPrepStore.dispatch({ loading: true })

      subscriptionRef.current = forkJoin([
        submissionService.getJobSubmission(id),
        submissionService.submissionStatus(id),
      ]).subscribe(errorAndCompletionObserver)
    }

    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
      }
    }
  }, [errorAndCompletionObserver, subscriptionRef, id])

  if (loading || !submission) {
    return <CircularProgress size={16} sx={{ color: 'text.disabled' }} />
  }

  return <CandidatePrepCandidateEditorContent />
}
