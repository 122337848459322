import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {
  alpha,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  StepLabel,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { ButtonProps } from '@mui/material/Button'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import { useTheme } from '@mui/material/styles'
import { GorillaDialog } from '@procom-labs/atoms'
import {
  CartCandidateStatuses,
  getInitials,
  ICartCandidate,
  JobSubmissionAtsStatusLabels,
  useSubjectSelector,
  useSubscriptionRef,
  VendorCodes,
} from '@procom-labs/common'

import AddToList from '@submission-portal/assets/add-to-list.jpg'
import { CandidateCartEmailPreview } from '@submission-portal/components/submission-view/candidate-cart-email-preview'
import { CandidateCartPrepForm } from '@submission-portal/components/submission-view/candidate-cart-prep-form'
import { MultipleCandidateSuccess } from '@submission-portal/features/submission-detail/components'
import { vendorStorage } from '@submission-portal/lib'
import { cartService } from '@submission-portal/services'
import { avatarService } from '@submission-portal/services/avatar.service'
import { submissionStore } from '@submission-portal/stores'

const NoResultsSvg = styled('svg')(() => ({
  aspectRatio: '106/165',
}))

interface StatusButtonProps extends ButtonProps {
  entityStatus: CartCandidateStatuses
}

const StatusButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'entityStatus',
})<StatusButtonProps>(({ theme, entityStatus }) => ({
  width: '172px',
  color: theme.palette.common.black,
  fontSize: `${theme.typography.body1.fontSize} !important`,
  fontWeight: theme.typography.body1.fontWeight,
  textTransform: 'none',
  backgroundColor:
    entityStatus === CartCandidateStatuses.Ready
      ? alpha(theme.palette.success.light, 0.6)
      : alpha(theme.palette.warning.light, 0.6),
  flex: '0 0 auto',
  '&:hover': {
    backgroundColor:
      entityStatus === CartCandidateStatuses.Ready
        ? alpha(theme.palette.success.light, 0.4)
        : alpha(theme.palette.warning.light, 0.4),
  },
}))

const CandidateCartList: FC<{
  candidates: ICartCandidate[]
  label: string
  labelBackgroundColor: string
  setSelectedCandidate: (candidate: ICartCandidate) => void
  removeCandidate: (jobSubmissionId: string) => void
}> = React.memo(
  ({
    candidates,
    setSelectedCandidate,
    removeCandidate,
    label,
    labelBackgroundColor,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
      <>
        <Box
          key={label}
          sx={{
            width: 'fit-content',
            px: 2,
            mb: 1,
            borderRadius: '4px',
            backgroundColor: labelBackgroundColor,
          }}
        >
          <Typography>{label}</Typography>
        </Box>

        <List
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '15px',
            padding: '0px',
            mb: 2,
          }}
        >
          {candidates?.map((candidate, index) => (
            <div key={candidate.atsJobSubmissionId ?? candidate.email}>
              <Grid
                container
                component="li"
                columnSpacing={1}
                rowSpacing={2}
                sx={{ p: '10px 18px', justifyContent: 'space-between' }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src=""
                    alt={`${candidate.firstName}-${candidate.lastName}`}
                    sx={{
                      backgroundColor: candidate.backgroundColor
                        ? `#${candidate.backgroundColor}`
                        : '000000',
                      width: 44,
                      height: 44,
                      fontWeight: 400,
                      fontSize: 20,
                      lineHeight: 20,
                      color: (innerTheme) => innerTheme.palette.common.white,
                    }}
                  >
                    {getInitials(
                      `${candidate.firstName} ${candidate.lastName}`,
                      2
                    ) || <PersonIcon sx={{ width: 35, height: 35 }} />}
                  </Avatar>
                  <Typography sx={{ ml: '12px' }}>
                    {`${candidate.firstName} ${candidate.lastName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 'fit-content',
                  }}
                >
                  <EmailOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, mt: '2px' }}
                  />
                  <Typography>{candidate.email}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    minWidth: 'fit-content',
                    marginLeft: 'auto',
                  }}
                >
                  <StatusButton
                    entityStatus={candidate.entityStatus}
                    onClick={() => setSelectedCandidate(candidate)}
                  >
                    {candidate.entityStatus === CartCandidateStatuses.Ready &&
                      t('submissionList.multipleCandidates.ready')}
                    {candidate.entityStatus !== CartCandidateStatuses.Ready &&
                      t('submissionList.multipleCandidates.prepCandidate')}
                  </StatusButton>
                  <Tooltip
                    title={t(
                      'submissionList.multipleCandidates.removeCandidate'
                    )}
                  >
                    <IconButton
                      onClick={() => removeCandidate(candidate.jobSubmissionId)}
                      edge="end"
                      aria-label="delete"
                      sx={{ ml: '8px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {index < candidates.length - 1 && (
                <Divider key={`divider-${candidate.atsJobSubmissionId}`} />
              )}
            </div>
          ))}
        </List>
      </>
    )
  }
)

export const CandidateCartModal: FC<{}> = () => {
  const { t } = useTranslation('main')
  const modalRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))
  const [showEmail, setShowEmail] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const activeStep = showSuccessScreen ? 2 : showEmail ? 1 : 0

  const refreshCartSubRef = useSubscriptionRef()
  const [selectedCandidate, setSelectedCandidate] = useState<ICartCandidate>()
  const avatarColors = useObservable(() => avatarService.avatarsColors$)

  const isVendorVanderHouwen =
    vendorStorage.get()?.vendorCode === VendorCodes.VNDRHWN

  const { isCandidateCartOpen, cart, submissions, unsavedChanges } =
    useSubjectSelector(submissionStore, [
      'isCandidateCartOpen',
      'cart',
      'submissions',
      'unsavedChanges',
    ])

  const title = useMemo(() => {
    if (showEmail && cart) {
      return t('submissionDetail.reviewAndSubmit.stepperLabel')
    }
    if (selectedCandidate) {
      return selectedCandidate.entityStatus === CartCandidateStatuses.Ready
        ? t('submissionList.multipleCandidates.candidateDetails')
        : t('submissionList.multipleCandidates.addCandidateDetails')
    }

    return t('submissionList.multipleCandidates.modalTitle')
  }, [t, showEmail, cart, selectedCandidate])

  const selectedCandidates = useMemo(
    () =>
      !cart
        ? []
        : cart.entities.reduce((acc, current) => {
            const found = submissions.find(
              (submission) => submission.jobSubmissionId === current.id
            )
            if (found) {
              const backgroundColor = avatarColors?.find(
                (c) => c.avatarId === found.atsJobSubmissionId
              )?.color

              acc.push({
                backgroundColor: backgroundColor ?? '',
                firstName: found.firstName,
                lastName: found.lastName,
                email: found.email,
                jobSubmissionId: found.jobSubmissionId,
                atsJobSubmissionId: found.atsJobSubmissionId,
                entityStatus: current.status,
                status: found.status,
                submissionSource: found.submissionSource,
                atsSubmissionStatus: found.atsSubmissionStatus,
              })
            }
            return acc
          }, [] as ICartCandidate[]),
    [cart, submissions, avatarColors]
  )

  const unpreppedCandidates = useMemo(
    () =>
      selectedCandidates.filter(
        (c) => c.entityStatus !== CartCandidateStatuses.Ready
      ),
    [selectedCandidates]
  )

  const preppedCandidates = useMemo(
    () =>
      selectedCandidates.filter(
        (c) => c.entityStatus === CartCandidateStatuses.Ready
      ),
    [selectedCandidates]
  )

  const enableBack = useMemo(() => {
    if (!selectedCandidate) return false
    if (selectedCandidate?.entityStatus !== CartCandidateStatuses.Ready) {
      const currentIndex = unpreppedCandidates.findIndex(
        (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
      )

      if (currentIndex >= 1) {
        return true
      }
    }
    return false
  }, [selectedCandidate, unpreppedCandidates])

  const handleReleaseCart = useCallback(() => {
    setShowEmail(true)
  }, [])

  const refreshCart = useCallback(() => {
    if (cart) {
      refreshCartSubRef.current = cartService
        .getCartById(cart.id, true)
        .subscribe({
          next: (c) => {
            submissionStore.dispatch({
              cart: c,
            })
          },
        })
    }
  }, [cart, refreshCartSubRef])

  const handleRemoveCandidateModal = useCallback((jobSubmissionId: string) => {
    submissionStore.dispatch({
      touchedCandidate: jobSubmissionId,
      isCandidateRemoveModalOpen: true,
    })
  }, [])

  const handleBackToList = useCallback(
    (refresh: boolean = true) => {
      if (refresh) {
        refreshCart()
      }
      setSelectedCandidate(undefined)
      setShowEmail(false)
    },
    [refreshCart]
  )

  const handleNextToSuccessScreen = useCallback(() => {
    setShowSuccessScreen(true)
  }, [])

  useEffect(() => {
    if (!isCandidateCartOpen) {
      setSelectedCandidate(undefined)
      setShowEmail(false)
    }
  }, [isCandidateCartOpen])

  const handleSaveBeforeClose = useCallback(() => {
    if (unsavedChanges && selectedCandidate) {
      submissionStore.dispatch({
        isSaveConfirmationModalOpen: true,
      })
    } else if (showSuccessScreen) {
      submissionStore.dispatch({ isCandidateCartOpen: false, cart: null })
      setShowSuccessScreen(false)
    } else {
      submissionStore.dispatch({ isCandidateCartOpen: false })
    }
  }, [unsavedChanges, showSuccessScreen, selectedCandidate])

  const backHandler = useCallback(() => {
    const currentIndex = selectedCandidates.findIndex(
      (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
    )
    if (currentIndex < 1) return

    setSelectedCandidate(selectedCandidates[currentIndex - 1])
    if (modalRef?.current?.firstChild) {
      modalRef.current.scrollTop = 0
    }
  }, [selectedCandidates, selectedCandidate?.atsJobSubmissionId])

  const nextHandler = useCallback(() => {
    if (selectedCandidate) {
      const isCandidatePrepped =
        selectedCandidate.entityStatus === CartCandidateStatuses.Ready

      if (isCandidatePrepped) {
        handleBackToList()
        return
      }

      const currentIndex = unpreppedCandidates.findIndex(
        (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
      )
      if (currentIndex < 0) return

      if (
        unpreppedCandidates &&
        currentIndex === unpreppedCandidates.length - 1
      ) {
        handleBackToList()
        return
      }
      setSelectedCandidate(unpreppedCandidates[currentIndex + 1])
      if (modalRef?.current?.firstChild) {
        modalRef.current.scrollTop = 0
      }
    }
  }, [selectedCandidate, unpreppedCandidates, handleBackToList])

  // Automatically remove submitted candidates from the cart
  // Possible scenarios:
  // 1. Candidate was in the cart but then the user submitted them individually
  // 2. Candidate was in the cart but then the user submitted them via bullhorn
  // In both of these cases the submissionSource will not be null so we remove them
  useEffect(() => {
    const filteredCandidates = selectedCandidates.reduce((acc, candidate) => {
      if (isVendorVanderHouwen) {
        // Prevent the VH's candidates that have been submitted via BH from being removed from cart so it can be submitted again
        if (
          candidate.submissionSource !== null &&
          candidate.atsSubmissionStatus !== JobSubmissionAtsStatusLabels.Handoff
        ) {
          acc.push(candidate.jobSubmissionId)
        }
      } else if (candidate.submissionSource !== null) {
        acc.push(candidate.jobSubmissionId)
      }
      return acc
    }, [] as string[])

    let subscription: Subscription | null = null
    if (filteredCandidates.length && cart && !isCandidateCartOpen) {
      subscription = cartService
        .removeCandidatesFromCart(cart.id, filteredCandidates)
        .subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: {
                ...cart,
                entities: [
                  ...cart.entities.filter(
                    (c) => !filteredCandidates.includes(c.id)
                  ),
                ],
              },
              isCandidateRemoveModalOpen: false,
            })
          },
        })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [selectedCandidates, cart, isCandidateCartOpen])

  return (
    <GorillaDialog
      open={isCandidateCartOpen}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
    >
      <IconButton
        sx={{ position: 'absolute', top: '4px', right: '4px' }}
        aria-label={t('common.aria.close')}
        onClick={handleSaveBeforeClose}
      >
        <CloseIcon color="primary" />
      </IconButton>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        p={3}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Grid
          xs={5}
          sx={{ minWidth: 'fit-content' }}
          item
          flexDirection="row"
          alignItems="baseline"
        >
          <DialogTitle
            variant="h5"
            sx={{ p: 0, flex: 'unset', whiteSpace: 'nowrap' }}
          >
            {title}
          </DialogTitle>
        </Grid>
        <Grid item xs={5} sx={{ minWidth: '300px', marginLeft: 'auto' }}>
          <Stepper alternativeLabel activeStep={activeStep}>
            <Step
              key="prep"
              completed={
                (selectedCandidates.length &&
                  preppedCandidates.length === selectedCandidates.length) ||
                false
              }
            >
              <StepLabel>
                {t('submissionList.multipleCandidates.prepCandidates')}
              </StepLabel>
            </Step>
            <Step key="review" completed={showSuccessScreen}>
              <StepLabel>{t('submissionList.reviewAndSubmit')}</StepLabel>
            </Step>
            <Step key="success" completed={showSuccessScreen}>
              <StepLabel>
                {t('submissionDetail.submissionSuccess.stepperLabel2')}
              </StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
      <DialogContent ref={modalRef}>
        {showSuccessScreen ? (
          <MultipleCandidateSuccess
            totalCandidates={preppedCandidates.length}
          />
        ) : showEmail && cart ? (
          <CandidateCartEmailPreview
            cart={cart}
            handleBackToList={handleBackToList}
            handleNextToSuccessScreen={handleNextToSuccessScreen}
          />
        ) : (
          <>
            {selectedCandidate ? (
              <CandidateCartPrepForm
                candidate={selectedCandidate}
                nextHandler={nextHandler}
                backHandler={backHandler}
                enableBack={enableBack}
                handleBackToList={handleBackToList}
              />
            ) : (
              <>
                {!selectedCandidates?.length ? (
                  <Grid
                    container
                    sx={{
                      // justifyContent: 'center',
                      backgroundColor: 'white',
                      py: 6,
                    }}
                    columnGap={6}
                  >
                    <Grid
                      item
                      xs={5}
                      ml={8}
                      sx={{ display: 'flex', justifyContent: 'end' }}
                    >
                      <NoResultsSvg sx={{ width: { xs: '25vw', lg: '10vw' } }}>
                        <use href="/assets/svg/no-results-character.svg#default" />
                      </NoResultsSvg>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" mb={1} component="h5">
                        {t('submissionList.multipleCandidates.noCandidates')}
                      </Typography>
                      <Typography mb={1}>
                        {t(
                          'submissionList.multipleCandidates.noCandidatesBody'
                        )}
                      </Typography>
                      <Box
                        component="img"
                        src={AddToList}
                        sx={{ width: '300px', mt: 2 }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {unpreppedCandidates.length > 0 && (
                      <CandidateCartList
                        candidates={unpreppedCandidates}
                        label={t(
                          'submissionList.multipleCandidates.unpreppedCandidates'
                        )}
                        labelBackgroundColor={alpha(
                          theme.palette.warning.light,
                          0.4
                        )}
                        setSelectedCandidate={setSelectedCandidate}
                        removeCandidate={handleRemoveCandidateModal}
                      />
                    )}
                    {preppedCandidates.length > 0 && (
                      <CandidateCartList
                        candidates={preppedCandidates}
                        label={t(
                          'submissionList.multipleCandidates.preppedCandidates'
                        )}
                        labelBackgroundColor={alpha(
                          theme.palette.success.light,
                          0.4
                        )}
                        setSelectedCandidate={setSelectedCandidate}
                        removeCandidate={handleRemoveCandidateModal}
                      />
                    )}
                    {preppedCandidates.length === selectedCandidates.length && (
                      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                          sx={{ boxShadow: 'none !important' }}
                          variant="contained"
                          onClick={handleReleaseCart}
                        >
                          {t('submissionList.multipleCandidates.submit')}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
    </GorillaDialog>
  )
}
