import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EditOutlined } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Divider, Stack, StackProps, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { GorillaStack } from '@procom-labs/atoms'
import {
  candidateOnePagerStore,
  OnePagerModes,
  useSubjectSelector,
} from '@procom-labs/common'

import { OnePagerHeadline } from './one-pager-headline'

interface OnePagerWrapperProps {
  onSave?: () => void
  toggleCurrentView?: () => void
  currentView?: OnePagerModes
  sx?: StackProps['sx']
  hideButtons?: boolean
}
export const OnePagerWrapper: FC<OnePagerWrapperProps> = ({
  onSave,
  toggleCurrentView,
  currentView = OnePagerModes.VIEW,
  children,
  sx,
  hideButtons,
}) => {
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])
  const theme = useTheme()
  const { t } = useTranslation('main')
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))
  if (!onePager) return null
  return (
    <GorillaStack
      direction="column"
      sx={{
        p: isMobileOrTab ? 2 : 4,
        backgroundColor: 'background.default',
        ...sx,
      }}
    >
      {isMobile && !hideButtons && (
        <Stack direction="row" gap={2} sx={{ ml: 'auto' }}>
          {onSave && (
            <Button
              size="small"
              variant="outlined"
              onClick={onSave}
              startIcon={<SaveIcon />}
              sx={{ height: 'fit-content' }}
            >
              {t('common.btn.save')}
            </Button>
          )}
          <Button
            size="small"
            variant="outlined"
            onClick={toggleCurrentView}
            endIcon={
              currentView === OnePagerModes.VIEW ? <EditOutlined /> : undefined
            }
            sx={{ height: 'fit-content' }}
          >
            {currentView === OnePagerModes.VIEW
              ? t('common.btn.edit')
              : t('common.btn.preview')}
          </Button>
        </Stack>
      )}
      {onePager.parsedResumeData.profile?.name && (
        <GorillaStack direction="row" justifyContent="space-between">
          <Box sx={{ flex: '3' }}>
            <OnePagerHeadline
              name={onePager.parsedResumeData.profile.name}
              candidateTitle={onePager.candidateTitle}
              currentView={currentView}
            />
          </Box>
          {!isMobile && !hideButtons && (
            <Stack
              direction="row"
              flexWrap="wrap"
              gap={1}
              sx={{ flex: '1', justifyContent: 'end' }}
            >
              {onSave && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={onSave}
                  startIcon={<SaveIcon />}
                  sx={{ height: 'fit-content' }}
                >
                  {t('common.btn.save')}
                </Button>
              )}
              <Button
                size="small"
                variant="outlined"
                onClick={toggleCurrentView}
                endIcon={
                  currentView === OnePagerModes.VIEW ? (
                    <EditOutlined />
                  ) : undefined
                }
                sx={{ height: 'fit-content' }}
              >
                {currentView === OnePagerModes.VIEW
                  ? t('common.btn.edit')
                  : t('common.btn.preview')}
              </Button>
            </Stack>
          )}
        </GorillaStack>
      )}
      <Divider
        sx={{
          width: '100%',
          mt: '16px',
          border: '1px solid',
          opacity: 0.3,
        }}
      />
      {children}
    </GorillaStack>
  )
}
