import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Observable, of } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ButtonClose, GorillaDialog } from '@procom-labs/atoms'
import {
  candidateOnePagerStore,
  CandidateTabs,
  getRandomColor,
  IEntityType,
  IJobSubmission,
  IOnePagerForm,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import {
  CandidateDetails,
  mapFormValuesToCandidateOnePager,
  Resume,
  useCandidateOnePager,
} from '@procom-labs/organisms'

import { CandidateOnePager } from '@submission-portal/components'
import { environment } from '@submission-portal/environment'
import {
  authService,
  candidateOnePagerService,
  jobAiService,
  submissionService,
} from '@submission-portal/services'

export const SubmissionOnePagerForm: FC<{
  submission: IJobSubmission
  openPreview: () => void
  openForm: () => void
}> = ({ submission, openPreview, openForm }) => {
  const { onePager } = useSubjectSelector(candidateOnePagerStore, ['onePager'])
  const theme = useTheme()
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'))

  const { getFormDefaultValues } = useCandidateOnePager()
  const subscriptionRef = useSubscriptionRef()
  const [dialogOpen, setDialogOpen] = useState(false)

  const defaultValues: IOnePagerForm | {} = useMemo(
    () => (onePager ? getFormDefaultValues(onePager) : {}),
    [getFormDefaultValues, onePager]
  )
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')
  const clientData = useObservable(() => authService.clientData$)

  const { reset, ...formMethods } = useForm<IOnePagerForm>({
    defaultValues,
  })

  const handleRemoveFromSubmission = useCallback(() => {
    subscriptionRef.current = submissionService
      .disableOnePagerOnJobSubmission()
      .subscribe({
        next: () => {
          setDialogOpen(false)
          openForm()
        },
      })
  }, [subscriptionRef, openForm])
  const handleNext = useCallback(() => {
    if (onePager) {
      const values = formMethods.getValues()

      const editedOnePager = mapFormValuesToCandidateOnePager(values, onePager)
      subscriptionRef.current = candidateOnePagerService
        .saveOnePager(editedOnePager)
        .subscribe({
          next: (data) => {
            candidateOnePagerStore.dispatch({
              onePager: data
            })
            openPreview()
          },
        })
    }
  }, [subscriptionRef, formMethods, onePager, openPreview])

  const handleSave = useCallback(() => {
    if (onePager) {
      const values = formMethods.getValues()

      const editedOnePager = mapFormValuesToCandidateOnePager(values, onePager)

      subscriptionRef.current = candidateOnePagerService
        .saveOnePager(editedOnePager)
        .subscribe({
          next: (data) => {
            reset(values)
            candidateOnePagerStore.dispatch({
              onePager: data
            })
            addAlert({
              message: t('submissionDetail.candidateDetails.alert.save'),
            })
          },
        })
    }
  }, [subscriptionRef, addAlert, t, formMethods, reset, onePager])

  const avatarColor: string = useMemo(() => {
    return getRandomColor()
  }, [])

  const resume$: Observable<ArrayBuffer | null> = useMemo(() => {
    if (submission.candidate) {
      return submission.candidate.resume?.fileStorageId
        ? submissionService.getResume(submission.candidate.resume.fileStorageId)
        : of(null)
    }
    return of(null)
  }, [submission.candidate])

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (formMethods.formState.isDirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [formMethods.formState.isDirty])

  useEffect(() => {
    reset(defaultValues, { keepDirty: true })
  }, [defaultValues, reset])

  return (
    <FormProvider {...formMethods} reset={reset}>
      <Stack sx={{ p: 2, width: '100%' }} gap={3}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={2}
          justifyContent="space-between"
          alignItems={isMobile ? 'start' : 'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: 'text.secondary' }}
            variant="h5"
            fontWeight={700}
          >
            {t('submissionDetail.candidateOnePager.title')}
          </Typography>
          <Stack direction="row" gap={2} sx={{ marginLeft: 'auto' }}>
            <Button variant="outlined" onClick={() => setDialogOpen(true)}>
              {t('submissionDetail.candidateOnePager.btn.remove')}
            </Button>
            <Button
              variant="contained"
              sx={{ width: '120px' }}
              onClick={handleNext}
            >
              {t('common.btn.next')}
            </Button>
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: '100%',
            border: '1px solid',
            opacity: 0.3,
          }}
        />

        <CandidateDetails
          isSubmissionPortal
          jobAiService={jobAiService}
          avatarColor={avatarColor}
          data={{
            ...submission.candidate,
            comments: `<p>${submission.candidate.comments}</p>`,
            showBillRate: clientData?.showBillRateToHiringManager || false,
          }}
          environment={environment}
          onePagerTab={
            <CandidateOnePager
              hideCandidateDescription
              onSave={handleSave}
              isClientPortal
            />
          }
          resumeTab={
            <Resume
              resume$={resume$}
              candidateResume={submission.candidate?.resume ?? null}
              entityType={IEntityType.JobSubmission}
              entityId={submission.candidate.jobSubmissionId}
            />
          }
          notesTab={<Box />}
          timelineTab={<Box />}
          additionalDocumentsTab={<Box />}
          disableButtons
          candidateTabDefault={CandidateTabs.resume}
          handleCandidateRejected={() => {}}
          handleCandidateRequestInterview={() => {}}
          handleCandidateExtendOffer={() => {}}
          handleContactAm={() => {}}
        />
      </Stack>
      <GorillaDialog open={dialogOpen}>
        <DialogTitle>
          {t('submissionDetail.candidateOnePager.removeDialogTitle')}
        </DialogTitle>
        <ButtonClose handleClose={() => setDialogOpen(false)} />
        <DialogContent>
          <Typography>
            {t('submissionDetail.candidateOnePager.removeDialogContent')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setDialogOpen(false)}>
            {t('common.btn.cancel')}
          </Button>
          <Button variant="contained" onClick={handleRemoveFromSubmission}>
            {t('common.btn.remove')}
          </Button>
        </DialogActions>
      </GorillaDialog>
    </FormProvider>
  )
}
