import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  alpha,
  Box,
  BoxProps,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material'
import {
  DataObject,
  DataOption,
  ICandidate,
  IResume,
  useSubjectSelector,
} from '@procom-labs/common'
import {
  SelectWithOptions,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'
import { ResumeUploader } from '@procom-labs/organisms'

import { PdfViewerDialog } from '@submission-portal/components'
import {
  useDeleteCandidatesPrepResource,
  useUploadResourceChange,
} from '@submission-portal/components/candidate-prep/hooks/candidate-prep-core-hooks'
import {
  ResourceFetchScope,
  useAtsResourcesFetcher,
  useGetUserId,
  useScopedFetchFile,
  useUploadResource,
  useViewOrDownloadFile,
} from '@submission-portal/hooks'
import {
  candidatesPrepStore,
  ICandidatesPrepStoreState,
} from '@submission-portal/stores/candidates-prep-store'
import {
  FileActions,
  FileModes,
  IDetailedSubmission,
} from '@submission-portal/types'

type ValidPropertyBases = 'resume' | 'coverPage'

type RequiredProperties<T extends string> = `${T}Selection` &
  `${T}File` &
  `${T}FileBlob` &
  `${T}Mode` &
  `${T}Selections`

type HasValidProperties = {
  [K in ValidPropertyBases]: RequiredProperties<K> extends keyof ICandidatesPrepStoreState
    ? K
    : never
}[ValidPropertyBases]

interface SubmissionPrepFileSelectionProps extends BoxProps {
  label: string
  scope: ResourceFetchScope
  property: HasValidProperties
  placeholder: string
  required?: boolean
}

export const SubmissionPrepFileSelector: React.FC<
  SubmissionPrepFileSelectionProps
> = ({ label, scope, property, placeholder, required = false, ...rest }) => {
  const [processing, setProcessing] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')
  const [showingFile, setShowingFile] = useState(false)

  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const selectionProp =
    `${property}Selection` as keyof ICandidatesPrepStoreState
  const fileProp = `${property}File` as keyof ICandidatesPrepStoreState
  const fileBlobProp = `${property}FileBlob` as keyof ICandidatesPrepStoreState
  const modeProp = `${property}Mode` as keyof ICandidatesPrepStoreState
  const selectionsProp =
    `${property}Selections` as keyof ICandidatesPrepStoreState
  const attachToSubmissionProp = `is${
    property.charAt(0).toUpperCase() + property.slice(1)
  }UploadEnabled` as keyof ICandidate

  const state = useSubjectSelector(candidatesPrepStore, [
    'candidate',
    selectionProp,
    selectionsProp,
    fileProp,
    modeProp,
  ])

  const submission = state.candidate
  const persistedValue = submission?.candidate?.[property]
  const rootOption = useMemo(() => {
    if (persistedValue) {
      const { name, extension, fileStorageId } = persistedValue
      if (fileStorageId) {
        const title = `${name}${extension}`.trim()
        return { title, id: fileStorageId }
      }
    }

    return undefined
  }, [persistedValue])

  const mode = state[modeProp]
  const uploadedFile = state[fileProp]
  const selection = state[selectionProp] ?? ''
  const selections = (state[selectionsProp] as DataOption<DataObject>[]) ?? []
  const attachToSubmissionValue =
    submission?.candidate?.[attachToSubmissionProp]

  const userId = useGetUserId()

  const handleModeChange = useCallback(
    (e) =>
      candidatesPrepStore.dispatch({
        [modeProp]: e.target.value,
      }),
    [modeProp]
  )

  const uploadResource = useUploadResource()
  const onChangeResource = useUploadResourceChange(
    userId,
    fileProp,
    fileBlobProp
  )

  const handleDeleteResource = useDeleteCandidatesPrepResource(
    fileProp,
    fileBlobProp
  )

  const onFetchFile = useScopedFetchFile()
  const fetchAtsResource = useAtsResourcesFetcher(onFetchFile)

  const handleToggleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      candidatesPrepStore.dispatch({
        candidate: {
          ...submission,
          ...(submission?.candidate && {
            candidate: {
              ...submission?.candidate,
              [attachToSubmissionProp]: event.target.checked,
            },
          }),
        } as IDetailedSubmission,
      })
    },
    [attachToSubmissionProp, submission]
  )

  const handleSelection = useCallback(
    (newSelection, isRoot) => {
      // In case we are back to the root option, this means we need to clear selection
      candidatesPrepStore.dispatch({
        [selectionProp]: isRoot ? '' : newSelection,
      })
      setBlobUrl('')
    },
    [selectionProp]
  )

  const handleResumes = useViewOrDownloadFile(
    userId,
    setBlobUrl,
    setShowingFile,
    setProcessing
  )

  const handleFileAction = useCallback(
    (actionType: string) => {
      if (!selection) {
        if (persistedValue) {
          handleResumes([persistedValue], actionType)
        }
      } else {
        setProcessing(true)
        fetchAtsResource([
          {
            fileId: selection as string,

            // For view, we need to convert to pdf so it will work for all
            convertToPdf: actionType === FileActions.View,
            scope,
          },
        ]).subscribe({
          next: (resumes) => {
            handleResumes(resumes, actionType)
          },
          error: () => {
            handleError()
            setProcessing(false)
          },
        })
      }
    },
    [
      selection,
      fetchAtsResource,
      scope,
      persistedValue,
      handleResumes,
      handleError,
    ]
  )

  const handleDownload = useCallback((): void => {
    if (mode === FileModes.Upload && uploadedFile) {
      const resumeFile = uploadedFile as IResume
      handleResumes(
        [
          {
            extension: resumeFile.extension,
            fileStorageId: resumeFile.fileStorageId,
            name: resumeFile.name,
            size: resumeFile.size,
          },
        ],
        FileActions.Download
      )
    } else {
      handleFileAction(FileActions.Download)
    }
  }, [handleResumes, uploadedFile, handleFileAction, mode])

  const handleView = useCallback((): void => {
    if (mode === FileModes.Upload && uploadedFile) {
      handleResumes(
        [
          {
            extension: (uploadedFile as IResume).extension,
            fileStorageId: (uploadedFile as IResume).fileStorageId,
            name: (uploadedFile as IResume).name,
            size: (uploadedFile as IResume).size,
          },
        ],
        FileActions.View
      )
    } else {
      handleFileAction(FileActions.View)
    }
  }, [handleResumes, uploadedFile, handleFileAction, mode])

  const handleCloseViewDialog = useCallback((): void => {
    setShowingFile(false)
  }, [])

  return (
    <Box {...rest}>
      {blobUrl && showingFile && (
        <PdfViewerDialog blobUrl={blobUrl} onClose={handleCloseViewDialog} />
      )}

      <Box display="flex">
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 500,
            lineHeight: '32px',
            letterSpacing: '0.15px',
            color: '#00000099',
          }}
        >
          {label}
        </Typography>
        {required && (
          <Typography color="error" style={{ marginLeft: '4px' }}>
            *
          </Typography>
        )}
      </Box>
      <RadioGroup
        sx={{ mt: '1rem' }}
        row
        value={mode}
        onChange={handleModeChange}
      >
        <FormControlLabel
          value="select"
          control={<Radio />}
          label={t('candidatePreb.selectFileFromBullhorn')}
        />
        <FormControlLabel
          value="upload"
          control={<Radio />}
          label={t('candidatePreb.uploadNew')}
        />
      </RadioGroup>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          {mode === FileModes.Select && (
            <SelectWithOptions
              sx={{ width: '100%' }}
              rootOption={rootOption}
              placeholder={placeholder}
              value={selection}
              onChange={handleSelection}
              disabled={!selections.length}
              displayEmpty
              clearable
              options={selections}
            />
          )}
          {mode === FileModes.Upload && (
            <ResumeUploader
              direction="column"
              uploadResume={uploadResource}
              deleteResume={handleDeleteResource}
              onChange={onChangeResource}
              value={uploadedFile ? (uploadedFile as IResume) : null}
              fileStyles={{
                width: '100%',
                border: `1px solid ${alpha('#000', 0.23)}`,
                borderRadius: '4px',
                padding: '0px 8px 5px 8px',
                margin: '0px',
              }}
            />
          )}
        </Grid>

        {(mode === FileModes.Select
          ? selection || rootOption
          : uploadedFile) && (
          <Grid
            item
            xs={6}
            container
            alignItems="center"
            spacing={2}
            justifyContent="flex-start"
          >
            <Grid item>
              <Button size="medium" onClick={handleView} disabled={processing}>
                {t('common.btn.view')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                onClick={handleDownload}
                disabled={processing}
              >
                {t('common.btn.download')}
              </Button>
            </Grid>
            {typeof attachToSubmissionValue === 'boolean' && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      name="checkedSwitch"
                      color="primary"
                      checked={attachToSubmissionValue}
                      onChange={handleToggleChange}
                    />
                  }
                  label={t('candidatePreb.attachToSubmission')}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
