import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Controller, FieldError, Merge, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import {
  alpha,
  Autocomplete,
  Chip,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { GridProps } from '@mui/material/Grid/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Preloader } from '@procom-labs/atoms'
import {
  FeatureFlagNames,
  IEmailTemplate,
  IJobSubmission,
  PreferredLanguageType,
  useIsFeatureFlagEnabled,
  validEmailRegex,
} from '@procom-labs/common'
import {
  AllowedDelimiters,
  CheckboxHookField,
  SelectHookField,
  useAlert,
} from '@procom-labs/molecules'
import { FormHookEditor } from '@procom-labs/organisms'

import { environment } from '@submission-portal/environment'
import { authService, submissionService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'
import {
  EmailRecipientType,
  IEmailForm,
  SendEmailCopyKey,
} from '@submission-portal/types'

import { SubLabel } from './submission-update/form-parts'
import { getUpdatePayload } from './submission-update/submission-update-form'

type EmailReviewProps = {
  templateData?: IEmailTemplate[]
  setTemplateData: React.Dispatch<
    React.SetStateAction<IEmailTemplate[] | undefined>
  >
  containerProps?: Partial<GridProps>
  hideLanguageSelect?: boolean
  isLoading: boolean
  error: string | null
  setReFetchEmailContent?: React.Dispatch<React.SetStateAction<boolean>>
  submission?: IJobSubmission
  setIsDraftSaved?: React.Dispatch<React.SetStateAction<boolean>>
  isDraftSaved?: boolean
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const EditorToolbar = [
  { name: 'history', items: ['undo', 'redo'] },
  { name: 'styles', items: ['styles'] },
  { name: 'fontsize', items: ['fontsize'] },
  { name: 'fontcolor', items: ['forecolor'] },
  {
    name: 'formatting',
    items: ['bold', 'italic', 'underline'],
  },
  {
    name: 'lists',
    items: ['bullist', 'numlist'],
  },
  {
    name: 'alignment',
    items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
  },
  {
    name: 'indentation',
    items: ['outdent', 'indent'],
  },
  {
    name: 'fullscreen',
    items: ['fullscreen'],
  },
]

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-root': {
    paddingRight: '76px',
    minHeight: '46px',
    '&:hover::before': {
      borderBottomWidth: '1px !important',
    },
    '&::before': {
      borderColor: alpha(theme.palette.common.black, 0.23),
    },
  },
  '& .Mui-disabled': {
    '&::before': {
      borderBottomStyle: 'solid !important',
    },
  },
}))

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRight: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  borderLeft: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  '& .MuiFormHelperText-root': {
    paddingLeft: '8px',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: 'calc(100% - 38px)',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    '& .MuiInput-root:not(.Mui-error)::before': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    '& .MuiFormHelperText-root': {
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
}))

const EmailInputList: React.FC<{
  control: any
  name: string
  id: string
  label: string
  error?: Merge<FieldError, (FieldError | undefined)[]>
  endAdornment?: React.ReactNode
  disabled?: boolean
  emailRecipientType?: EmailRecipientType
  handleEmailRecipientChange?: (recepients: string[]) => void
}> = ({
  control,
  name,
  id,
  label,
  error,
  endAdornment,
  disabled = false,
  emailRecipientType,
}) => {
  const handleEmailChange = useCallback(
    (
      onChange: (emails: string[]) => void,
      previousEmails: string[],
      newEmail: string
    ) => {
      // the "to" field is allowed to have only one email, the rest can have multiple
      // since this is common func between onChange and onBlur, we will keep previous emails in case user only click on the field and go away
      if (emailRecipientType === EmailRecipientType.TO) {
        onChange(newEmail ? [newEmail] : previousEmails)
      } else {
        const updatedPreviousEmails =
          newEmail && !previousEmails.includes(newEmail)
            ? [...previousEmails, newEmail]
            : previousEmails
        onChange(updatedPreviousEmails)
      }
    },
    [emailRecipientType]
  )
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...rest } }) => (
        <CustomAutocomplete
          multiple
          id={id}
          freeSolo
          options={[]}
          disabled={disabled}
          disableClearable
          clearOnBlur
          renderInput={(params) => {
            const previousValue = `${params.inputProps.value}`
            return (
              <CustomTextField
                {...params}
                aria-label={label}
                variant="standard"
                error={!!error}
                autoComplete="off"
                helperText={
                  Array.isArray(error)
                    ? error.find((e) => e?.type === 'email')?.message
                    : error?.message
                }
                InputProps={{
                  ...params.InputProps,
                  inputProps: {
                    ...params.inputProps,
                    autoComplete: 'off',
                    sx: { display: disabled ? 'none' : 'initial' },
                  },
                  sx: { pt: 1, pl: 1 },
                  autoComplete: 'off',
                  startAdornment: (
                    <>
                      <InputAdornment position="start" sx={{ height: '24px' }}>
                        {label}
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {endAdornment}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                onBlur={(e) => {
                  const newValue = (e.target as HTMLInputElement)?.value ?? ''
                  handleEmailChange(onChange, [...rest.value], newValue)
                }}
                onChange={(event) => {
                  const currentValue = `${event.target.value}`
                  const isDelimiterCharacter = currentValue?.charAt(
                    currentValue.length - 1
                  )
                  if (
                    isDelimiterCharacter &&
                    AllowedDelimiters.includes(isDelimiterCharacter) &&
                    validEmailRegex.exec(`${previousValue}`)
                  ) {
                    onChange([...rest.value, previousValue])
                  }
                }}
              />
            )
          }}
          renderTags={(value, getTagProps) =>
            (value as string[]).map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(e, data) => {
            const newValue = (e.target as HTMLInputElement)?.value ?? ''
            handleEmailChange(onChange, data as string[], newValue)
          }}
          {...rest}
        />
      )}
    />
  )
}

export const EmailPreview = forwardRef<any, EmailReviewProps>(
  (
    {
      templateData,
      setTemplateData,
      containerProps,
      isLoading,
      error,
      hideLanguageSelect = false,
      setReFetchEmailContent,
      submission,
      setIsDraftSaved,
      isDraftSaved,
      setOpenModal,
    },
    ref
  ) => {
    const { addAlert } = useAlert()
    const { t } = useTranslation('main')
    const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
      FeatureFlagNames.ResumeOnePager
    )

    const [showBcc, setShowBcc] = useState(false)

    const formInstance = useFormContext<IEmailForm>()
    const currentUser = useObservable(() => authService.authData$)

    const jobSubmissionValues = submission
      ? getUpdatePayload(
          submission,
          IS_ONE_PAGER_ENABLED,
          currentUser?.user?.preferredLanguage
        )
      : null

    const formLanguage = formInstance.watch('language')

    const initialEditorValue = useMemo(() => {
      if (templateData && formLanguage) {
        return templateData.find((content) => content.language === formLanguage)
          ?.emailContent
      }
      return undefined
    }, [templateData, formLanguage])

    const onLanguageChange = useCallback(
      (event) => {
        const { emailContent, emailSubject } = formInstance.getValues()

        const template = templateData?.find(
          (data) => data.language === event.target.value
        )

        // Set the subject value to the one that was saved in state
        formInstance.setValue('emailSubject', template?.emailSubject ?? '')

        // Update the template data for the previous language so that edits are persisted
        setTemplateData((prevState) =>
          prevState?.map((content) =>
            content.language === formLanguage
              ? {
                  ...content,
                  emailSubject,
                  emailContent,
                }
              : content
          )
        )
      },
      [templateData, formInstance, formLanguage, setTemplateData]
    )

    const toggleBcc = useCallback(
      () => setShowBcc((prevState) => !prevState),
      [setShowBcc]
    )

    const handleCandidateHighlights = useCallback(
      (isHandleConfirm = false) => {
        const isChecked = formInstance.getValues('includeHighlights') ?? false
        submissionStore.dispatch({ isTogglingCandidateHighlight: true })
        if (isDraftSaved && !isHandleConfirm) {
          setOpenModal?.(true)
          return
        }
        if (!jobSubmissionValues) return

        submissionService
          .updateSubmission({
            ...jobSubmissionValues,
            includeHighlights: isChecked,
          })
          .subscribe({
            complete: () => {
              setReFetchEmailContent?.(true)
              setIsDraftSaved?.(false)
              addAlert({
                message: t('submissionDetail.candidateDetails.alert.save'),
              })
            },
            error: () => {
              addAlert({
                severity: 'error',
                message: t('submissionDetail.candidateDetails.alert.failed'),
              })
            },
          })
      },
      [
        formInstance,
        isDraftSaved,
        jobSubmissionValues,
        setOpenModal,
        setReFetchEmailContent,
        setIsDraftSaved,
        addAlert,
        t,
      ]
    )

    const handleCloseModal = useCallback(() => {
      setOpenModal?.(false)
      const includeHighlights = formInstance.getValues('includeHighlights')
      formInstance.setValue('includeHighlights', !includeHighlights)
    }, [formInstance, setOpenModal])

    const handleModalConfirm = useCallback(() => {
      handleCandidateHighlights(true)
      setOpenModal?.(false)
    }, [handleCandidateHighlights, setOpenModal])

    useImperativeHandle(ref, () => ({
      handleCloseModal,
      handleModalConfirm,
    }))

    return (
      <Grid
        container
        item
        {...containerProps}
        sx={{
          margin: 'auto',
        }}
      >
        <Grid item xs={12}>
          {(() => {
            if (isLoading) {
              return <Preloader center />
            }
            if (initialEditorValue) {
              return (
                <Grid container spacing={2}>
                  {!hideLanguageSelect && (
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <SelectHookField
                        label={t(
                          'submissionDetail.candidateDetails.form.language'
                        )}
                        control={formInstance.control}
                        name="language"
                        fullWidth={false}
                        showPleaseSelect={false}
                        size="small"
                        sx={{
                          height: '44px',
                          backgroundColor: 'background.default',
                        }}
                        onChange={onLanguageChange}
                      >
                        <MenuItem
                          key={PreferredLanguageType.EN}
                          value={PreferredLanguageType.EN}
                        >
                          <ListItemText
                            primary={t(
                              `common.jobGenerator.preferredLanguage.${PreferredLanguageType.EN}`
                            )}
                          />
                        </MenuItem>
                        <MenuItem
                          key={PreferredLanguageType.FR}
                          value={PreferredLanguageType.FR}
                        >
                          <ListItemText
                            primary={t(
                              `common.jobGenerator.preferredLanguage.${PreferredLanguageType.FR}`
                            )}
                          />
                        </MenuItem>
                      </SelectHookField>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <EmailInputList
                      control={formInstance.control}
                      name="emailFrom"
                      id="emailFrom"
                      label={t('submissionEmail.from')}
                      disabled
                      error={formInstance.formState.errors.emailFrom}
                    />
                    <EmailInputList
                      control={formInstance.control}
                      name="emailTo"
                      id="email-to"
                      label={t('submissionEmail.to')}
                      error={formInstance.formState.errors.emailTo}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            mr: 1,
                            height: '30.75px',
                            position: 'absolute',
                            right: '0',
                            top: '11.25px',
                          }}
                        >
                          <Button
                            id="btn-email-preview-toggle-bcc"
                            size="small"
                            variant="text"
                            onClick={toggleBcc}
                          >
                            {t('submissionEmail.bcc')}
                          </Button>
                        </InputAdornment>
                      }
                      emailRecipientType={EmailRecipientType.TO}
                    />

                    <EmailInputList
                      control={formInstance.control}
                      name="emailToCc"
                      id="email-cc"
                      label={t('submissionEmail.cc')}
                      error={formInstance.formState.errors.emailToCc}
                    />

                    {showBcc && (
                      <EmailInputList
                        control={formInstance.control}
                        name="emailToBcc"
                        id="email-bcc"
                        label={t('submissionEmail.bcc')}
                        error={formInstance.formState.errors.emailToBcc}
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      margin: '-12px 0 -6px 14px',
                    }}
                  >
                    <CheckboxHookField
                      name="sendEmailCopyToSender"
                      control={formInstance.control}
                      label={t('submissionEmail.sendEmailCopyToSender')}
                      handleChange={(checked) => {
                        localStorage.setItem(SendEmailCopyKey, `${checked}`)
                        formInstance.setValue('sendEmailCopyToSender', checked)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      control={formInstance.control}
                      name="emailSubject"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('submissionEmail.subject')}
                          error={!!formInstance.formState.errors.emailSubject}
                          sx={{
                            width: '100%',
                            backgroundColor: 'background.default',
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {submission && (
                    <Grid item xs={12} mt={1} mb={-1}>
                      <Controller
                        control={formInstance.control}
                        name="includeHighlights"
                        render={({ field }) => (
                          <Grid item container>
                            <SubLabel htmlFor="comments">
                              {t(
                                'submissionDetail.candidateDetails.form.comments.label'
                              )}
                            </SubLabel>
                            <Tooltip
                              title={t(
                                'submissionDetail.candidateDetails.form.comments.includeHighlights'
                              )}
                            >
                              <Switch
                                {...field}
                                sx={{ mt: '-7px', ml: '4px' }}
                                checked={!!field.value}
                                onChange={(e) => {
                                  const { checked } = e.target
                                  formInstance.setValue(field.name, checked)
                                  handleCandidateHighlights()
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormHookEditor
                      name="emailContent"
                      environment={environment}
                      control={formInstance.control}
                      initialValue={initialEditorValue}
                      plugins={[
                        'autoresize',
                        'fullscreen',
                        'image',
                        'quickbars',
                        'lists',
                      ]}
                      ignoreInitialChangeEvent={false}
                      editorSettings={{
                        content_style: '.display-none { display:none }',
                        visual: false,
                        toolbar_sticky: true,
                        toolbar: EditorToolbar,
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }

            return (
              <div className="center-screen">
                <Typography paragraph variant="h3">
                  {t('submissionEmail.notFound')}
                </Typography>
                {error && (
                  <Typography variant="h5" paragraph>
                    {error}
                  </Typography>
                )}
              </div>
            )
          })()}
        </Grid>
      </Grid>
    )
  }
)
