import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'

import GridOnIcon from '@mui/icons-material/GridOn'
import ListIcon from '@mui/icons-material/List'
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { BrandLogo } from '@procom-labs/atoms'
import {
  CartStatuses,
  IEntityType,
  useDebouncedSubjectSelector,
  useServices,
  useSubjectSelector,
} from '@procom-labs/common'
import {
  SearchBarWrapper,
  useDataPaginationProps,
  useDataSortHandler,
} from '@procom-labs/molecules'
import { SearchBar } from '@procom-labs/organisms'

import GroupSend from '@submission-portal/assets/GroupSend.svg'
import {
  AtsJobSubmissionView,
  RemoveConfirmationModal,
  SubmissionDataFilterModal,
} from '@submission-portal/components'
import { CandidateCartModal } from '@submission-portal/components/submission-view/candidate-cart-modal'
import { useGetAtsJobId } from '@submission-portal/hooks'
import { credStorage } from '@submission-portal/lib'
import { cartService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'
import { DisplayModes, gorillaWorksUrl } from '@submission-portal/types'

import {
  useSubmissionDataColumns,
  useSubmissionDataRecords,
} from '../submission-view/hooks'
import { SubmissionDataSearchBar } from '../submission-view/submission-data-search-bar'

export const SubmissionDataView: React.FC = () => {
  const { t } = useTranslation()
  const columns = useSubmissionDataColumns()

  const jobId = useGetAtsJobId()

  const { isSearchOpen, cart } = useSubjectSelector(submissionStore, [
    'isSearchOpen',
    'cart',
  ])

  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))

  // Use debounce to apply delay to the search query
  const {
    searchFilter,
    displayMode,
    submissions: rows,
  } = useDebouncedSubjectSelector(
    submissionStore,
    ['searchFilter', 'displayMode', 'submissions'],
    300
  )

  const { rowCount, loading: rowsLoading } =
    useSubmissionDataRecords(searchFilter)

  const { clientJobService } = useServices()
  const { activeJob, isLoading } = useSubjectSelector(
    clientJobService.clientJobStore,
    ['activeJob', 'isLoading']
  )

  const handleChangeKeyword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      submissionStore.dispatch({
        searchFilter: {
          ...searchFilter,
          keyword: e.target.value,
        },
      })
    },
    [searchFilter]
  )

  const openCandidateCartModal = useCallback(() => {
    submissionStore.dispatch({ isCandidateCartOpen: true })
  }, [])

  const handleDisplayModeChange = useCallback((_event, nextView) => {
    if (nextView) {
      submissionStore.dispatch({ displayMode: nextView })
    }
  }, [])

  useEffect((): void => {
    sessionStorage.setItem('rootPath', '/data')
  }, [])

  useEffect(() => {
    let subscription: Subscription | null
    const atsJobId = jobId ?? credStorage.get()?.atsJobId

    if (atsJobId) {
      subscription = clientJobService
        .getJobByAtsIdAndFlag(atsJobId, true)
        .subscribe({
          next: (jobDetail) => {
            clientJobService.clientJobStore.dispatch({
              activeJob: jobDetail,
            })
          },
        })
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [jobId, clientJobService])

  useEffect(() => {
    let subscription: Subscription | null
    if (activeJob && !cart) {
      subscription = cartService
        .getCartByEntity(IEntityType.Job, activeJob.id, true)
        .subscribe({
          next: (cartData) => {
            const activeCart = cartData.find(
              (c) => c.status === CartStatuses.InProgress
            )
            if (activeCart) {
              submissionStore.dispatch({
                cart: activeCart,
              })
            }
          },
        })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [jobId, activeJob, cart])

  // Not needed right now but will be needed later on when the feature is fully implemented
  // useEffect(() => {
  //   if (jobId) {
  //     subscriptionRef.current = candidateAtsService
  //       .getJobCollaborators({ atsJobId: parseInt(jobId, 10) })
  //       .subscribe({
  //         next: (collaborators) => submissionStore.dispatch({ collaborators }),
  //       })
  //   }
  // }, [jobId, candidateAtsService, subscriptionRef, submissionStore])

  const paginationProps = useDataPaginationProps(submissionStore, searchFilter)

  const onSortModelChange = useDataSortHandler({
    searchFilter,
    store: submissionStore,
  })

  return (
    <Box sx={{ m: isMobileOrTab ? 2 : 4 }}>
      <Link
        href={gorillaWorksUrl}
        target="_blank"
        rel="noopener"
        sx={{ position: 'absolute', top: '32px', zIndex: 0 }}
      >
        <BrandLogo
          withText
          textColor="black"
          style={{
            marginTop: 'auto',
            padding: '2px',
            borderRadius: '5px',
          }}
        />
      </Link>
      <AtsJobSubmissionView
        rows={rows}
        rowCount={rowCount}
        loading={rowsLoading || isLoading || !activeJob}
        columns={columns}
        onSortModelChange={onSortModelChange}
        {...paginationProps}
      >
        <Grid
          item
          container
          xs={12}
          columnGap={3.5}
          sx={{
            justifyContent: 'end',
            alignItems: 'end',
            '& > .MuiGrid-root': { justifyContent: 'end' },
            mt: isMobileOrTab ? 9 : 0,
          }}
        >
          <Grid item sx={{ display: 'flex' }}>
            <SubmissionDataSearchBar
              handleChangeKeyword={handleChangeKeyword}
            />
            <Tooltip title={t('submissionList.multipleCandidates.tooltip')}>
              <IconButton
                sx={{ ml: 3.5, mb: '-7px' }}
                onClick={openCandidateCartModal}
              >
                <Badge badgeContent={cart?.entities.length} color="primary">
                  <svg data-src={GroupSend} data-loading="lazy" />
                </Badge>
              </IconButton>
            </Tooltip>

            <SearchBarWrapper searchOpen={isSearchOpen}>
              <SearchBar
                handleChangeKeyword={handleChangeKeyword}
                keyword={searchFilter?.keyword}
              />
            </SearchBarWrapper>
          </Grid>
        </Grid>
        <Box sx={{ position: 'relative', height: '20px' }}>
          <ToggleButtonGroup
            exclusive
            value={displayMode}
            sx={{ height: '36px', position: 'absolute', top: '8px', zIndex: 1 }}
            onChange={handleDisplayModeChange}
          >
            <ToggleButton
              value={DisplayModes.Card}
              aria-label="card"
              sx={{
                '&:not(.Mui-selected)': {
                  backgroundColor: theme.palette.background.default,
                },
              }}
            >
              <GridOnIcon />
            </ToggleButton>

            <ToggleButton
              value={DisplayModes.List}
              aria-label="list"
              sx={{
                '&:not(.Mui-selected)': {
                  backgroundColor: theme.palette.background.default,
                },
              }}
            >
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <SubmissionDataFilterModal />
        <CandidateCartModal />
        <RemoveConfirmationModal />
      </AtsJobSubmissionView>
    </Box>
  )
}
