import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Grid, Stack, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import {
  errorToString,
  ICart,
  IEmailTemplate,
  IEntityType,
  IJobSubmission,
  useServices,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { yupResolver } from '@hookform/resolvers/yup'
import { EmailPreview } from '@submission-portal/features/submission-detail/components/email-preview'
import { PreviewModal } from '@submission-portal/features/submission-detail/components/preview-modal'
import { useHandleSaveEmailContent } from '@submission-portal/hooks'
import { cartService, submissionService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'
import {
  EmailFormDefaultValues,
  IEmailForm,
  SendEmailCopyKey,
} from '@submission-portal/types'
import {
  buildEmailObject,
  emailFormValidation,
  loadCartEmailContent,
} from '@submission-portal/utils'

export const CandidateCartEmailPreview: FC<{
  cart: ICart
  handleBackToList: (refresh?: boolean) => void
  handleNextToSuccessScreen: () => void
}> = ({ cart, handleBackToList, handleNextToSuccessScreen }) => {
  const { addAlert } = useAlert()
  const { emailObject, searchFilter } = useSubjectSelector(submissionStore, [
    'emailObject',
    'searchFilter',
  ])

  const [open, setOpen] = useState(false)
  const [previewCandidates, setPreviewCandidates] = useState<IJobSubmission[]>(
    []
  )

  const [templateData, setTemplateData] = useState<IEmailTemplate[]>()
  const [isDraftSaved, setIsDraftSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')

  const theme = useTheme()
  const previewRef = useSubscriptionRef()
  const submitRef = useSubscriptionRef()
  const cleanUpRef = useSubscriptionRef()

  const { clientJobService } = useServices()
  const { activeJob } = useSubjectSelector(clientJobService.clientJobStore, [
    'activeJob',
  ])

  const { t } = useTranslation()
  const validationSchema = emailFormValidation(
    t('submissionEmail.invalidEmail'),
    t('submissionEmail.requiredList')
  )

  const initialValues = useMemo(
    () => ({
      ...EmailFormDefaultValues,
      sendEmailCopyToSender: localStorage.getItem(SendEmailCopyKey) === 'true',
    }),
    []
  )

  const formInstance = useForm<IEmailForm>({
    values: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const submitCleanUp = useCallback(() => {
    cleanUpRef.current = submissionService
      .getSubmissionData(submissionStore, jobId, searchFilter)
      .subscribe({
        next: () => {
          setIsLoading(false)
          handleBackToList(false)
        },
      })
  }, [cleanUpRef, jobId, searchFilter, handleBackToList])

  const submitHandler = useCallback(
    (data: IEmailForm): void => {
      if (cart?.id && templateData) {
        setIsLoading(true)
        submitRef.current = submissionService
          .submitMultipleCandidates(
            cart.id,
            JSON.stringify(buildEmailObject(data, templateData))
          )
          .subscribe({
            complete: () => {
              addAlert({
                message: t('submissionDetail.candidateDetails.alert.submitted'),
              })
              if (emailObject) {
                submissionStore.dispatch({
                  emailObject: {
                    ...emailObject,
                    emailToCc: data.emailToCc,
                  },
                })
              }
              handleNextToSuccessScreen()
              submitCleanUp()
            },
            error: (err) =>
              addAlert({
                severity: 'error',
                message: errorToString(err),
              }),
          })
      }
    },
    [
      addAlert,
      t,
      templateData,
      cart?.id,
      submitRef,
      submitCleanUp,
      emailObject,
      handleNextToSuccessScreen,
    ]
  )

  const handleOpenPreview = useCallback(() => {
    if (activeJob) {
      previewRef.current = cartService
        .getCartJobSubmissions(cart.id, IEntityType.Job, activeJob.id)
        .subscribe({
          next: (r) => {
            setPreviewCandidates(r)
            setOpen(true)
          },
        })
    }
  }, [activeJob, cart.id, previewRef])

  const saveEmailContent = useHandleSaveEmailContent(
    formInstance,
    cart.id,
    setIsSaving,
    templateData,
    true
  )

  const handleSaveEmailContent = useCallback(() => {
    saveEmailContent()
    setIsDraftSaved(true)
  }, [saveEmailContent])

  useEffect(() => {
    setIsLoading(true)
    let subscription: Subscription | null = null
    subscription = loadCartEmailContent(
      cart,
      formInstance,
      setTemplateData,
      setError,
      submissionStore
    )
    subscription.add(() => {
      setIsLoading(false)
    })
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [cart])

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={formInstance.handleSubmit(submitHandler)}>
        <PreviewModal
          open={open}
          handleClose={() => setOpen(false)}
          jobSubmissions={previewCandidates}
        />
        <Grid
          container
          xs={12}
          sx={{
            margin: 'auto',
            marginBottom: '16px',
            justifyContent: 'end',
            position: 'relative',
          }}
        >
          <Tooltip
            title={t('submissionDetail.reviewAndSubmit.previewBtnTooltip')}
            placement="top"
          >
            <Button
              variant="outlined"
              type="button"
              id="btn-preview-client-portal"
              onClick={handleOpenPreview}
              sx={{
                backgroundColor: theme.palette.background.default,
                position: 'absolute',
              }}
            >
              {t('submissionDetail.reviewAndSubmit.previewBtnLabel')}
            </Button>
          </Tooltip>
        </Grid>
        <EmailPreview
          isLoading={isLoading}
          error={error}
          templateData={templateData}
          setTemplateData={setTemplateData}
          containerProps={{
            xs: 12,
            sx: { overflowY: 'auto' },
          }}
          setIsDraftSaved={setIsDraftSaved}
          isDraftSaved={isDraftSaved}
        />
        <Box
          sx={{
            width: '100%',
            py: 1,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            left: 0,
            px: '24px',
            backgroundColor: 'white',
            zIndex: 3,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button
            onClick={() => handleBackToList()}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              disabled={isSaving || !formInstance.formState.isValid}
              endIcon={isSaving && <CircularProgress size={18} />}
              variant="outlined"
              type="button"
              id="btn-save-draft"
              onClick={handleSaveEmailContent}
              sx={{ backgroundColor: theme.palette.background.default }}
              startIcon={!isSaving && <SaveIcon />}
            >
              {isSaving ? t('common.btn.saving') : t('common.btn.saveDraft')}
            </Button>
            <Button type="submit" variant="contained">
              {t('submissionList.btn.send')}
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  )
}
