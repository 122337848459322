import { useCallback, useRef } from 'react'
import { EMPTY, Subscription, switchMap } from 'rxjs'

import {
  IEntityType,
  useServices,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'

import { ISubmissionSummary } from '@submission-portal/models'
import { cartService, submissionService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'

export const useRemoveCandidateFromList = (): ((
  jobSubmissionId: string
) => void) => {
  const subscriptionRef = useSubscriptionRef()

  const { cart } = useSubjectSelector(submissionStore, ['cart'])
  const deleteCart = useCallback(() => {
    if (cart?.id) {
      return cartService.deleteCart(cart.id)
    }
    return EMPTY
  }, [cart?.id])

  const removeCandidate = useCallback(
    (jobSubmissionId: string) => {
      if (cart?.id) {
        return cartService.removeCandidatesFromCart(cart.id, [jobSubmissionId])
      }
      return EMPTY
    },
    [cart?.id]
  )
  return useCallback(
    (jobSubmissionId) => {
      if (cart && cart?.entities.length > 1) {
        subscriptionRef.current = removeCandidate(jobSubmissionId).subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: {
                ...cart,
                entities: [
                  ...cart.entities.filter((c) => c.id !== jobSubmissionId),
                ],
              },
              isCandidateRemoveModalOpen: false,
            })
          },
        })
      } else {
        subscriptionRef.current = deleteCart().subscribe({
          next: () => {
            submissionStore.dispatch({
              cart: null,
              isCandidateRemoveModalOpen: false,
            })
          },
        })
      }
    },
    [cart, deleteCart, removeCandidate, subscriptionRef]
  )
}

export const useAddCandidateToList = (): ((
  submission: ISubmissionSummary
) => void) => {
  const { clientJobService } = useServices()
  const { activeJob } = useSubjectSelector(clientJobService.clientJobStore, [
    'activeJob',
  ])
  const { cart, submissions } = useSubjectSelector(submissionStore, [
    'cart',
    'submissions',
  ])
  const subscriptionRef = useSubscriptionRef()
  const noSubmissionSubscriptionRef = useRef<Subscription | null>(null)

  const createCart = useCallback(
    (jobSubmissionId: string) => {
      if (activeJob) {
        return cartService.createCart({
          parentEntityId: activeJob.id,
          parentEntityType: IEntityType.Job,
          entityType: IEntityType.JobSubmission,
          entityIds: [jobSubmissionId],
          setEntitiesStatus: true,
        })
      }
      return EMPTY
    },
    [activeJob]
  )

  const addCandidateToCart = useCallback(
    (jobSubmissionId: string) => {
      if (cart?.id) {
        return cartService.addCandidatesToCart(cart.id, [jobSubmissionId], true)
      }
      return EMPTY
    },
    [cart?.id]
  )

  const handleUpdateCart = useCallback(
    (submission: ISubmissionSummary) => {
      if (!submission.jobSubmissionId) {
        noSubmissionSubscriptionRef.current = submissionService
          .createSubmissionFromAts(submission.atsJobSubmissionId)
          .pipe(
            switchMap(({ id, status }) => {
              const index = submissions.findIndex(
                (submissionSummary) =>
                  submissionSummary.atsJobSubmissionId ===
                  submission.atsJobSubmissionId
              )
              if (index >= 0) {
                const submissionsCopy = [...submissions]
                submissionsCopy[index].jobSubmissionId = id
                submissionsCopy[index].status = status
                submissionStore.dispatch({
                  submissions: [...submissionsCopy],
                })
              }

              if (cart?.id) {
                return addCandidateToCart(id)
              }
              return createCart(id)
            })
          )
          .subscribe({
            next: (c) =>
              submissionStore.dispatch({
                cart: c,
              }),
          })
      } else if (cart?.id) {
        subscriptionRef.current = addCandidateToCart(
          submission.jobSubmissionId
        ).subscribe({
          next: (c) =>
            submissionStore.dispatch({
              cart: c,
            }),
        })
      } else {
        subscriptionRef.current = createCart(
          submission.jobSubmissionId
        ).subscribe({
          next: (c) =>
            submissionStore.dispatch({
              cart: c,
            }),
        })
      }
      return () => {
        if (noSubmissionSubscriptionRef.current) {
          noSubmissionSubscriptionRef.current.unsubscribe()
        }
      }
    },
    [addCandidateToCart, createCart, cart?.id, subscriptionRef, submissions]
  )

  return handleUpdateCart
}
