import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useObservable } from 'rxjs-hooks'

import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Button, Card, Chip, Divider, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  getDuration,
  getInitials,
  JobSubmissionAtsStatusLabels,
  VendorCodes,
} from '@procom-labs/common'
import { DropDownActionMenuButton } from '@procom-labs/molecules'

import { MoreActions } from '@submission-portal/components/submission-view/components/more-actions'
import { SubmissionActions } from '@submission-portal/components/submission-view/components/submission-actions'
import {
  useGetClientActivityLabel,
  useGetDateDifference,
  useGetStatusColor,
} from '@submission-portal/components/submission-view/hooks'
import {
  useAddCandidateToList,
  useNavigateToSubmission,
} from '@submission-portal/hooks'
import { vendorStorage } from '@submission-portal/lib'
import { ISubmissionSummary } from '@submission-portal/models'
import { avatarService } from '@submission-portal/services/avatar.service'
import { submissionStore } from '@submission-portal/stores'
import { SubmissionSource } from '@submission-portal/types'

export const CandidateCard: React.FC<{
  candidate: ISubmissionSummary
}> = ({ candidate }) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const [candidateAvatarColor, setCandidateAvatarColor] = useState('')
  const avatarsColors = useObservable(() => avatarService.avatarsColors$, [])
  const navigateToSubmission = useNavigateToSubmission()
  const addCandidateToList = useAddCandidateToList()

  const getStatusColor = useGetStatusColor()
  const getClientActivityLabel = useGetClientActivityLabel()

  const isVendorVanderHouwen =
    vendorStorage.get()?.vendorCode === VendorCodes.VNDRHWN

  const handleSubmit = useCallback(
    (): void => navigateToSubmission(candidate),
    [candidate, navigateToSubmission]
  )

  const handleGetInitials = useMemo(() => {
    return getInitials(`${candidate.firstName} ${candidate.lastName}`, 2)
  }, [candidate.firstName, candidate.lastName])

  const avatarColor = useMemo(
    () => `#${candidateAvatarColor}`,
    [candidateAvatarColor]
  )

  const durationLastModified = useMemo(
    () =>
      getDuration(
        new Date(candidate.dateLastModified).toISOString(),
        new Date().toISOString()
      ),
    [candidate.dateLastModified]
  )

  const isSubmittedViaAts =
    candidate.submissionSource === SubmissionSource.Bullhorn

  const getDateDifference = useGetDateDifference()

  useEffect(() => {
    if (avatarsColors) {
      const color = avatarService.getAvatarColor(candidate.atsJobSubmissionId)
      setCandidateAvatarColor(color)
    }
  }, [candidate.atsJobSubmissionId, avatarsColors])

  const handleRemoveCandidateFromModal = useCallback(
    (jobSubmissionId: string) => {
      submissionStore.dispatch({
        touchedCandidate: jobSubmissionId,
        isCandidateRemoveModalOpen: true,
      })
    },
    []
  )

  const submissionStatusLabel = useMemo(() => {
    if (!candidate.status) {
      /* VH can resubmit the candidate if the candidate has been submitted via BH */
      return isSubmittedViaAts && !isVendorVanderHouwen
        ? t('submissionList.bhSubmission')
        : t('submissionList.activityStatuses.submittedToManager')
    }
    return getClientActivityLabel(candidate.status)
  }, [
    candidate.status,
    getClientActivityLabel,
    isSubmittedViaAts,
    isVendorVanderHouwen,
    t,
  ])

  return (
    <Card
      sx={{
        border: '1px solid',
        borderRadius: '20px',
        padding: '24px',
        height: '100%',
        borderColor: theme.palette.divider,
      }}
    >
      <Stack gap={2} sx={{ height: '100%' }}>
        <Stack direction="row" gap={2} sx={{ flex: 1 }}>
          <Avatar
            src=""
            alt={`${candidate.firstName}-${candidate.lastName}`}
            sx={{
              backgroundColor: avatarColor,
              width: 60,
              height: 60,
              fontWeight: 400,
              fontSize: 20,
              lineHeight: 20,
              color: (innerTheme) => innerTheme.palette.common.white,
            }}
          >
            {`${handleGetInitials}` || (
              <PersonIcon sx={{ width: 35, height: 35 }} />
            )}
          </Avatar>
          <Stack sx={{ pt: '4px' }} gap="2px">
            <Typography variant="body1Bold500">
              {`${candidate.firstName} ${candidate.lastName}`}
            </Typography>
            <Chip
              label={
                <span>
                  {submissionStatusLabel}
                  {candidate.submissionSource !== null && (
                    <span
                      style={{
                        color: theme.palette.text.secondary,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        marginLeft: '6px',
                      }}
                    >
                      {getDateDifference(durationLastModified)}
                    </span>
                  )}
                </span>
              }
              sx={{
                px: '4px',
                py: '2px',
                '& .MuiChip-label': { whiteSpace: 'normal' },
                height: 'auto',
                width: 'fit-content',
                borderRadius: '4px',
                backgroundColor: getStatusColor(candidate?.status ?? ''),
              }}
            />
          </Stack>
        </Stack>

        <Divider />

        <Stack sx={{ px: '10px' }} gap="4px">
          <Typography variant="body2">
            <span style={{ color: theme.palette.text.secondary }}>
              {t('submissionList.views')}:
            </span>{' '}
            <span>{candidate.userActivityInsight?.totalViews ?? 0}</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: theme.palette.text.secondary }}>
              {t(`submissionList.status`)}:
            </span>{' '}
            <span>{candidate.atsSubmissionStatus}</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: theme.palette.text.secondary }}>
              {t(`submissionList.addedBy`)}:
            </span>{' '}
            <span>{candidate.addedBy}</span>
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          gap={2}
          sx={{
            flex: 1,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* VH can resubmit the candidate if the candidate has been submitted via BH and then moved back to Handoff */}
          {(candidate.submissionSource === SubmissionSource.Bullhorn &&
            !isVendorVanderHouwen) ||
          (candidate.submissionSource === SubmissionSource.Bullhorn &&
            isVendorVanderHouwen &&
            candidate.atsSubmissionStatus !==
              JobSubmissionAtsStatusLabels.Handoff) ? (
            <Typography
              variant="body2"
              sx={{
                minWidth: '150px',
                maxWidth: '200px',
                flex: 1,
                textAlign: 'center',
              }}
            >
              {t('submissionList.bhSubmission')}
            </Typography>
          ) : (
            <>
              {candidate.submissionSource ===
                SubmissionSource.BullhornUtility &&
              candidate.jobSubmissionId ? (
                <Button
                  onClick={handleSubmit}
                  variant="outlined"
                  sx={{
                    flex: 1,
                    minWidth: 200,
                    maxHeight: '32.5px',
                    boxShadow: 'none !important',
                    py: '4px',
                  }}
                >
                  {t('submissionList.btn.view')}
                </Button>
              ) : (
                <DropDownActionMenuButton
                  buttonProps={{
                    variant: 'contained',
                    color: 'primary',
                    children: t('common.btn.submit'),
                    sx: {
                      flex: 1,
                      minWidth: 200,
                      maxHeight: '32.5px',
                      boxShadow: 'none !important',
                      py: '4px',
                    },
                  }}
                  id="cta-submit-candidate"
                >
                  {(close) => (
                    <SubmissionActions
                      close={close}
                      handleSubmit={handleSubmit}
                      handleAddCandidateToList={addCandidateToList}
                      handleRemoveCandidateFromList={
                        handleRemoveCandidateFromModal
                      }
                      submission={candidate}
                    />
                  )}
                </DropDownActionMenuButton>
              )}
            </>
          )}

          <DropDownActionMenuButton
            buttonProps={{
              variant: 'outlined',
              color: 'primary',
              children: t('submissionList.btn.more'),
              sx: {
                flex: 1,
                maxHeight: '32.5px',
                minWidth: '95px',
                maxWidth: '200px',
                py: '4px',
              },
            }}
            id="btn-more-menu"
          >
            {(close) => <MoreActions close={close} />}
          </DropDownActionMenuButton>
        </Stack>
      </Stack>
    </Card>
  )
}
