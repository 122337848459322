import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { switchMap } from 'rxjs'

import { Replay } from '@mui/icons-material'
import { AlertTitle, Divider, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { GorillaAlert, GorillaBox, GorillaStack } from '@procom-labs/atoms'
import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  IJobSubmission,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'

import { ResumeCopilotProLoader } from '@submission-portal/components/resume-copilot-pro/components/resume-copilot-pro-loader'
import {
  candidateOnePagerService,
  contractorProfileService,
  submissionService,
} from '@submission-portal/services'

import { SubmissionOnePagerForm } from './submission-one-pager-form'

export const SubmissionOnePager: FC<{
  submission: IJobSubmission
  openPreview: () => void
  openForm: () => void
}> = ({ submission, openPreview, openForm }) => {
  const { t, i18n } = useTranslation('main')
  const onePagerSubscriptionRef = useSubscriptionRef()

  const { onePager, state, error, progress } = useSubjectSelector(
    candidateOnePagerStore,
    ['onePager', 'state', 'error', 'progress']
  )

  const handleAnalyze = useCallback(() => {
    const fileStorageId = submission.candidate?.resume?.fileStorageId
    const name = submission.candidate?.resume?.name
    if (fileStorageId && name) {
      onePagerSubscriptionRef.current = contractorProfileService
        .downloadResume(fileStorageId)
        .pipe(
          switchMap((data) => {
            const blob = new Blob([data], { type: 'application/pdf' })
            const file = new File([blob], `${name}.pdf`, {
              type: 'application/pdf',
            })
            const formData = new FormData()
            formData.append('file', file)
            return candidateOnePagerService.startResumeParse(
              formData,
              submission.id,
              fileStorageId
            )
          }),
          switchMap(({ onePagerId }) => {
            return submissionService.addOnePagerIdToResume(onePagerId)
          })
        )
        .subscribe()
    }
  }, [onePagerSubscriptionRef, submission])
  const handleContinue = useCallback(() => {
    onePagerSubscriptionRef.current = submissionService
      .disableOnePagerOnJobSubmission()
      .subscribe({
        next: () => {
          openPreview()
        },
      })
  }, [onePagerSubscriptionRef, openPreview])

  useEffect(() => {
    if (submission.submissionLanguage) {
      i18n.changeLanguage(submission.submissionLanguage)
    }
    return () => {
      i18n.changeLanguage('en')
    }
  }, [i18n, submission.submissionLanguage])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (state === CandidateOnePagerStateEnum.ERROR) {
    return (
      <GorillaStack direction="column" spacing={2} padding={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ color: 'text.secondary' }}
            variant="h5"
            fontWeight={700}
          >
            {t('submissionDetail.candidateOnePager.title')}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button variant="contained" onClick={handleContinue}>
              {t('submissionDetail.candidateOnePager.btn.continue')}
            </Button>
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: '100%',
            border: '1px solid',
            opacity: 0.3,
          }}
        />
        <GorillaAlert severity="error">
          <AlertTitle>{error}</AlertTitle>
          {t('submissionDetail.candidateOnePager.errors.genericBody')}
        </GorillaAlert>
        <GorillaBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            size="large"
            startIcon={<Replay />}
            onClick={handleAnalyze}
            sx={{
              borderRadius: '24px',
              textTransform: 'none',
            }}
          >
            {t('common.btn.retry')}
          </Button>
        </GorillaBox>
      </GorillaStack>
    )
  }

  if (
    state === CandidateOnePagerStateEnum.NO_FILE ||
    state === CandidateOnePagerStateEnum.FILE_LOADED ||
    state === CandidateOnePagerStateEnum.UPLOADING ||
    state === CandidateOnePagerStateEnum.PARSING
  ) {
    return <ResumeCopilotProLoader text={progress} />
  }

  if (!onePager)
    return (
      <GorillaAlert severity="error">
        <AlertTitle>
          {t('submissionDetail.candidateOnePager.errors.notFound')}
        </AlertTitle>
        {t('submissionDetail.candidateOnePager.errors.notFoundBody')}
      </GorillaAlert>
    )

  return (
    <SubmissionOnePagerForm
      submission={submission}
      openPreview={openPreview}
      openForm={openForm}
    />
  )
}
