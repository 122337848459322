import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  blueberryTwilightPaletteLight,
  cheerfulFiestaPaletteLight,
  mangoFusionPaletteLight,
  PieChart,
} from '@mui/x-charts'
import { OnePagerExperienceItem } from '@procom-labs/common'

const muiColors = [
  ...blueberryTwilightPaletteLight,
  ...cheerfulFiestaPaletteLight,
  ...mangoFusionPaletteLight,
]
const formatNumber = (value: number | string) => {
  const num = typeof value === 'string' ? parseFloat(value) : value
  if (Number.isInteger(num)) {
    return num
  }
  return parseFloat(num.toFixed(1))
}

export const Industry: FC<{
  industries: OnePagerExperienceItem[]
}> = memo(({ industries }) => {
  const { t } = useTranslation()

  const colors = useMemo(
    () =>
      muiColors.filter((value, index, self) => {
        return self.indexOf(value) === index
      }),
    []
  )

  const pieChartData = useMemo(
    () =>
      industries.map((industry, index) => ({
        label: industry.name,
        value: formatNumber(industry.yearsOfExperience),
        color: `${colors[index % colors.length]}`,
      })),
    [industries, colors]
  )

  if (pieChartData.length === 0) return null
  return (
    <Box p={3} sx={{ backgroundColor: 'onePager.boxBg', borderRadius: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 700 }}>
        {t('organisms.candidateOnePager.industry')}
      </Typography>
      <Grid container sx={{ minHeight: '200px' }}>
        <Grid item xs={12} md={4}>
          <PieChart
            slotProps={{
              legend: { hidden: true },
            }}
            height={200}
            series={[
              {
                data: pieChartData,
                type: 'pie',
                cx: '100px',
                cy: '100px',
                innerRadius: 55,
                outerRadius: 80,
              },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <List dense sx={{ py: 0 }}>
            {pieChartData.map((item) => (
              <ListItem key={item.label} sx={{ py: 0 }}>
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <SvgIcon>
                    <circle cx="12" cy="12" r="6" fill={item.color} />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  sx={{ py: 0 }}
                  primary={item.label}
                  primaryTypographyProps={{
                    sx: { display: 'inline' },
                  }}
                  secondary={item.value + ' years'}
                  secondaryTypographyProps={{
                    component: 'span',
                    sx: { display: 'inline', pl: 1 },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
})
