import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { map } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
  IEmailTemplate,
  IJobSubmission,
  SubmissionActions,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { ConfirmationPreviewDialog, useAlert } from '@procom-labs/molecules'

import { yupResolver } from '@hookform/resolvers/yup'
import { updateCandidatesAfterSubmission } from '@submission-portal/components/candidate-prep/candidate-prep-utils'
import { EmailPreview } from '@submission-portal/features/submission-detail/components/email-preview'
import {
  useErrorAndCompletionStoreObserver,
  useHandleSaveEmailContent,
} from '@submission-portal/hooks'
import { submissionService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import {
  EmailFormDefaultValues,
  IDetailedSubmission,
  IEmailForm,
} from '@submission-portal/types'
import {
  buildEmailObject,
  emailFormValidation,
  loadSubmissionEmailContent,
} from '@submission-portal/utils'

export const CandidatePrepEmailConfirmation: React.FC = () => {
  const { t } = useTranslation('main')
  const { id } = useParams()

  const submission = useObservable(() =>
    submissionService.currentSubmission$.pipe(
      map((o) => (o?.atsJobSubmissionId.toString() === id ? o : null))
    )
  )

  const { addAlert } = useAlert()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [templateData, setTemplateData] = useState<IEmailTemplate[]>()
  const submissionEmailRef = useSubscriptionRef()

  const validationSchema = emailFormValidation(
    t('submissionEmail.invalidEmail'),
    t('submissionEmail.requiredList')
  )

  const formInstance = useForm<IEmailForm>({
    values: EmailFormDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const errorAndCompletionObserver = useErrorAndCompletionStoreObserver({
    store: submissionStore,
  })

  const { clientSubmissionUnderProcessing, candidate } = useSubjectSelector(
    candidatesPrepStore,
    ['clientSubmissionUnderProcessing', 'candidate']
  )

  const handleSaveEmailContent = useHandleSaveEmailContent(
    formInstance,
    clientSubmissionUnderProcessing,
    setIsSaving,
    templateData
  )

  const finalize = useCallback((val: boolean) => {
    candidatesPrepStore.dispatch({
      clientSubmissionUnderProcessing: null,
      processing: val,
    })
  }, [])

  const onSubmit = useCallback(
    (data: IEmailForm) => {
      finalize(true)

      if (clientSubmissionUnderProcessing && templateData) {
        submissionService
          .submitCandidate(
            clientSubmissionUnderProcessing,
            {
              status: SubmissionActions.ClientSubmission,
              uploadDocuments: true,
            },
            JSON.stringify(buildEmailObject(data, templateData))
          )
          .subscribe({
            ...errorAndCompletionObserver,
            next: (updatedSummary) => {
              addAlert({
                message: t('candidatePreb.emailDialog.successSubtitle'),
                severity: 'success',
              })

              updateCandidatesAfterSubmission({
                id: clientSubmissionUnderProcessing,
                update: updatedSummary,
                store: submissionStore,
              })

              candidatesPrepStore.dispatch({
                processing: false,
                candidate: {
                  ...candidate,
                  status: updatedSummary.status,
                } as IDetailedSubmission,
              })
            },
          })
      }
    },
    [
      candidate,
      finalize,
      clientSubmissionUnderProcessing,
      errorAndCompletionObserver,
      addAlert,
      t,
      templateData,
    ]
  )

  useEffect(() => {
    if (candidate && clientSubmissionUnderProcessing) {
      setIsLoading(true)
      submissionEmailRef.current = loadSubmissionEmailContent(
        candidate,
        formInstance,
        setTemplateData,
        setError
      )
      submissionEmailRef.current.add(() => setIsLoading(false))
    }
  }, [
    candidate,
    candidate?.id,
    clientSubmissionUnderProcessing,
    formInstance,
    submissionEmailRef,
  ])

  return (
    <FormProvider {...formInstance}>
      <ConfirmationPreviewDialog
        isOpen={!!clientSubmissionUnderProcessing}
        title={t('candidatePreb.emailDialog.title')}
        subtitle={t('candidatePreb.emailDialog.subtitle')}
        onCancel={() => finalize(false)}
        onSubmit={formInstance.handleSubmit(onSubmit)}
        fullWidth
        maxWidth="md"
        additionalActions={
          <Button
            disabled={isSaving || !formInstance.formState.isValid}
            endIcon={isSaving && <CircularProgress size={18} />}
            variant="outlined"
            type="button"
            onClick={handleSaveEmailContent}
          >
            {isSaving ? t('common.btn.saving') : t('common.btn.saveDraft')}
          </Button>
        }
      >
        <EmailPreview
          setTemplateData={setTemplateData}
          templateData={templateData}
          isLoading={isLoading}
          error={error}
          submission={submission as IJobSubmission}
        />
      </ConfirmationPreviewDialog>
    </FormProvider>
  )
}
