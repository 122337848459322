import { MutableRefObject, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { interval, Subscription, switchMap } from 'rxjs'

import {
  CandidateOnePagerStateEnum,
  candidateOnePagerStore,
  GorillaResumeParsingStatus,
  useSubscriptionRef,
} from '@procom-labs/common'

import {
  candidateOnePagerService,
  contractorProfileService,
} from '@submission-portal/services'

type CandidateOnePagerParserHookType = {
  parse: (file: File, jobSubmissionId: string) => void
  pullOnePagerStatus: (taskId: string, jobSubmissionId: string) => void
  statusSubscriptionRef: MutableRefObject<Subscription | null>
}

enum ProgressMessages {
  STEP1 = 'Step 1/5: Parsing document pages',
  STEP2 = 'Step 2/5: Extracting resume metadata',
  STEP3 = 'Step 3/5: Extracting work experiences',
  STEP4 = 'Step 4/5: Extracting career summary',
  STEP5 = 'Step 5/5: Extraction complete, wrapping up',
  COMPLETE = 'Extraction complete',
}

export const useParser = (): CandidateOnePagerParserHookType => {
  const { t } = useTranslation('main')
  const subscriptionRef = useSubscriptionRef()
  const statusSubscriptionRef = useSubscriptionRef()

  const resetAll = useCallback((): void => {
    candidateOnePagerStore.dispatch({
      state: CandidateOnePagerStateEnum.NO_FILE,
      onePager: null,
      progress: '',
      error: null,
    })
  }, [])

  const getTransKey = useCallback((progress: string) => {
    switch (progress) {
      case ProgressMessages.STEP1:
        return 'step1'
      case ProgressMessages.STEP2:
        return 'step2'
      case ProgressMessages.STEP3:
        return 'step3'
      case ProgressMessages.STEP4:
        return 'step4'
      case ProgressMessages.STEP5:
        return 'step5'
      case ProgressMessages.COMPLETE:
        return 'complete'
      default:
        return null
    }
  }, [])

  // Pull the status every 2 seconds
  const pullOnePagerStatus = useCallback(
    (taskId: string, jobSubmissionId: string): void => {
      statusSubscriptionRef.current?.unsubscribe()
      const source = interval(2000)

      statusSubscriptionRef.current = source
        .pipe(
          switchMap(() =>
            candidateOnePagerService.getParsedResumeStatus(
              taskId,
              jobSubmissionId
            )
          )
        )
        .subscribe({
          next: (data) => {
            // If the job is complete, stop the interval and update the state

            const key = getTransKey(data.progressMessage)
            let progress = ''
            if (key) {
              progress = t(`candidateOnePagerParser.progressMessages.${key}`)
            }
            if (data.status === GorillaResumeParsingStatus.Complete) {
              statusSubscriptionRef.current?.unsubscribe()
              candidateOnePagerStore.dispatch({
                state: CandidateOnePagerStateEnum.DONE,
                progress,
                // Update the store with the one pager
                onePager: data,
              })
            } else {
              candidateOnePagerStore.dispatch({
                progress,
              })

              if (data.status === GorillaResumeParsingStatus.Error) {
                statusSubscriptionRef.current?.unsubscribe()
                candidateOnePagerStore.dispatch({
                  state: CandidateOnePagerStateEnum.ERROR,
                  error: t('submissionDetail.candidateOnePager.errors.generic'),
                })
              }
            }
          },
          error: () => {
            statusSubscriptionRef.current?.unsubscribe()
            candidateOnePagerStore.dispatch({
              state: CandidateOnePagerStateEnum.ERROR,
              error: t('submissionDetail.candidateOnePager.errors.generic'),
            })
          },
        })
    },
    [statusSubscriptionRef, t, getTransKey]
  )

  const parse = useCallback(
    (file: File, jobSubmissionId: string) => {
      if (!file) return

      // When parsing a new resume, reset the state
      resetAll()

      candidateOnePagerStore.dispatch({
        state: CandidateOnePagerStateEnum.UPLOADING,
      })

      const formData = new FormData()
      formData.append('file', file)
      formData.append('JobSubmissionId', jobSubmissionId)

      subscriptionRef.current = contractorProfileService
        .uploadResume(formData)
        .pipe(
          switchMap((response) => {
            if (!response.contractorResume.fileStorageId) {
              throw new Error(
                t('candidateOnePagerParser.errors.fileStorageError')
              )
            }
            return candidateOnePagerService.startResumeParse(
              formData,
              jobSubmissionId,
              response.contractorResume.fileStorageId
            )
          })
        )
        .subscribe({
          next: ({ taskId }) => {
            // Start pulling the status of the job
            pullOnePagerStatus(taskId, jobSubmissionId)
          },
          error: (e) => {
            candidateOnePagerStore.dispatch({
              state: CandidateOnePagerStateEnum.ERROR,
              error: `${t(
                'candidateOnePagerParser.errors.resumeUploadError'
              )}: ${e}`,
            })
          },
        })
    },
    [resetAll, pullOnePagerStatus, subscriptionRef, t]
  )

  return { parse, pullOnePagerStatus, statusSubscriptionRef }
}
