import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Field, FieldProps, FormikProvider, useFormik } from 'formik'
import { useObservableState } from 'observable-hooks'
import { TFuncKey, useTranslation } from 'react-i18next'
import { from, switchMap, tap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import { object as YupObject, string as YupString } from 'yup'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CopilotIcon } from '@procom-labs/atoms'
import {
  AiFeatureLocations,
  AvailabilityDefaults,
  conditionHandler,
  CurrencyTypes,
  DataObject,
  DataOption,
  DateAvailableDataTypes,
  EmploymentTypes,
  FeatureFlagNames,
  FileSource,
  gtmEvents,
  HighlightDocument,
  ICandidateHighlights,
  IJobSubmission,
  mapDataTypeToAvailability,
  mapToDataArray,
  PreferredLanguageType,
  RatePeriod,
  useIsFeatureFlagEnabled,
  UserWorkplacePreference,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  AvailabilityCustom,
  AvailabilityDateField,
  AvailabilityField,
  BillRateFormikField,
  CompensationField,
  CompensationFieldType,
  LoadingIndicator,
  LocationAutocomplete,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'
import { DocumentUploader, FormEditor } from '@procom-labs/organisms'

import { environment } from '@submission-portal/environment'
import { useTrackingWrapper } from '@submission-portal/hooks'
import { ISubmissionUpdatePayload } from '@submission-portal/models'
import {
  authService,
  candidateOnePagerService,
  jobAiService,
  submissionService,
} from '@submission-portal/services'
import {
  serviceDescriptorStore,
  submissionStore,
} from '@submission-portal/stores'

import { rollbarInstance } from '../../../../providers'
import { SubmissionDetailFileSelector } from '../submission-detail-file-selector'
import { SubHelperText, SubLabel } from './form-parts'
import { SubmissionSaveConfirmation } from './form-save-confirmation'
import { HighlightsFileSelect } from './highlights-file-select'

const WorkplaceTypeField: FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation('main')
  return (
    <Field name={name}>
      {({
        field,
        form,
      }: FieldProps<ISubmissionUpdatePayload['workplaceType']>) => (
        <Select
          {...field}
          fullWidth
          multiple
          sx={{
            backgroundColor: 'background.default',
            maxHeight: '56px',
          }}
          renderValue={(selectedValues: string[]) =>
            selectedValues.length ? (
              <Stack spacing={1} direction="row">
                {selectedValues.map((sel) => (
                  <Chip
                    key={sel}
                    label={t(`common.enums.jobSite.${sel}` as TFuncKey)}
                    variant="outlined"
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(e) => {
                      e.preventDefault()
                      form.setFieldValue(
                        'workplaceType',
                        selectedValues.filter((val) => val !== sel)
                      )
                    }}
                  />
                ))}
              </Stack>
            ) : (
              ''
            )
          }
        >
          {Object.values(UserWorkplacePreference).map((workplace) => (
            <MenuItem key={workplace} value={workplace}>
              <Checkbox
                size="small"
                sx={{ py: 0 }}
                checked={field.value.includes(workplace) ?? false}
              />
              <ListItemText
                primary={t(`common.enums.jobSite.${workplace}` as TFuncKey)}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </Field>
  )
}

type SubmissionUpdateFormProps = {
  submission: IJobSubmission
  nextHandler: () => void
  setResetEmail?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
  openPreview?: () => void
  backHandler?: () => void
  isCartSubmission?: boolean
  hideButtons?: boolean
}

export const getUpdatePayload = (
  submission: IJobSubmission,
  isOnePagerFeatureEnabled: boolean,
  preferredLanguage?: string
): ISubmissionUpdatePayload => {
  const { candidate, submissionLanguage } = submission
  const {
    billRate,
    comments,
    currency,
    salary,
    dateAvailable,
    dateAvailableType,
    dateAvailableUserInput,
    employmentType,
    includeCandidateHighlightsInEmail,
    ratePeriodType,
    isOnePagerEnabled,
    workplaceType,
  } = candidate

  return {
    customAvailability:
      dateAvailableType === DateAvailableDataTypes.Custom &&
      dateAvailableUserInput
        ? dateAvailableUserInput
        : '',
    comments: comments || '',
    dateAvailable: dateAvailable ?? '',
    salary: salary?.toString() ?? '',
    billRate: billRate?.toString() ?? '',
    ratePeriodType: ratePeriodType ?? RatePeriod.Hour,
    currency: currency || CurrencyTypes.CAD,
    employmentType: employmentType.toLowerCase() as EmploymentTypes,
    submissionLanguage: submissionLanguage ?? preferredLanguage ?? '',
    availability:
      dateAvailable && !dateAvailableType
        ? AvailabilityDefaults.DATE
        : mapDataTypeToAvailability(dateAvailableType),
    includeHighlights: includeCandidateHighlightsInEmail,
    isOnePagerEnabled: isOnePagerFeatureEnabled
      ? isOnePagerEnabled ?? true
      : false,
    location: {
      addressLine1:
        candidate.city && candidate.state
          ? `${candidate.city}, ${candidate.state}`
          : '',
      city: candidate.city,
      state: candidate.state,
    },
    workplaceType,
  }
}

export const SubmissionUpdateForm = forwardRef<any, SubmissionUpdateFormProps>(
  (
    {
      submission,
      nextHandler,
      backHandler,
      setResetEmail,
      setOpenModal,
      openPreview,
      isCartSubmission = false,
      hideButtons = false,
    },
    ref
  ) => {
    const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
      FeatureFlagNames.ResumeOnePager
    )
    const { t, i18n } = useTranslation('main')
    const { addAlert } = useAlert()
    const theme = useTheme()
    const isViewMode = sessionStorage.getItem('isView') === 'true'
    const handleError = useDefaultErrorHandler(
      addAlert,
      t('common.alert.somethingWrong')
    )
    const currentUser = useObservable(() => authService.authData$)

    const initialValues = getUpdatePayload(
      submission,
      IS_ONE_PAGER_ENABLED,
      currentUser?.user?.preferredLanguage
    )

    const [resumeOptions, setResumeOptions] = useState<
      DataOption<DataObject>[]
    >([])
    const [resumeSelection, setResumeSelection] = useState<string>('')
    const [additionalDocumentsOptions, setAdditionalDocumentsOptions] =
      useState<DataOption<DataObject>[]>([])

    const [action, setAction] = useState<'save' | 'next'>()
    const [isSaving, setIsSaving] = useState(false)
    const [resumeFocused, setResumeFocused] = useState(false)
    const [additionalDocumentFocused, setAdditionalDocumentFocused] =
      useState(false)
    const [isBadResumeCall, setIsBadResumeCall] = useState(false)
    const [isBadHighlightsFileCall, setIsBadHighlightsFileCall] =
      useState(false)
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const subscriptionRef = useSubscriptionRef()
    const handleSaveSubscriptionRef = useSubscriptionRef()
    const parseSubscriptionRef = useSubscriptionRef()

    const isLoading = useObservableState(jobAiService.isJobLoading$)

    const isValidResume =
      submission?.candidate?.resume?.size !== '0' || isFirstLoad

    const candidateHighlights = useObservable(
      () => jobAiService.candidateHighlightDeltas$,
      ''
    )

    const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
      'candidateAtsService',
    ])

    const startOnePagerParse = useCallback(
      (formData: FormData, fileStorageId: string) => {
        parseSubscriptionRef.current = candidateOnePagerService
          .startResumeParse(formData, submission.id, fileStorageId)
          .pipe(
            switchMap(({ onePagerId }) => {
              return submissionService.addOnePagerIdToResume(onePagerId)
            })
          )
          .subscribe()
      },
      [parseSubscriptionRef, submission.id]
    )

    const uploadResume = useCallback(
      (
        formData: FormData,
        onUploadProgress: (event: ProgressEvent) => void
      ) => {
        formData.append('JobSubmissionId', submission.id)
        return submissionService.uploadResume(formData, onUploadProgress).pipe(
          tap((resume) => {
            if (
              resume.fileStorageId &&
              IS_ONE_PAGER_ENABLED &&
              !isCartSubmission
            ) {
              startOnePagerParse(formData, resume.fileStorageId)
            }
          })
        )
      },
      [
        submission.id,
        startOnePagerParse,
        IS_ONE_PAGER_ENABLED,
        isCartSubmission,
      ]
    )

    const uploadAdditionalDocuments = useCallback(
      (
        formData: FormData,
        onUploadProgress: (event: ProgressEvent) => void
      ) => {
        formData.append('JobSubmissionId', submission.id)
        return submissionService.uploadAdditionalDocuments(
          formData,
          onUploadProgress
        )
      },
      [submission.id]
    )

    const deleteResume = useCallback(
      (fileStorageId: string) =>
        submissionService.deleteSubmissionResume(submission.id, fileStorageId),
      [submission.id]
    )
    const deleteDocument = useCallback(
      (fileStorageId: string) =>
        submissionService.deleteSubmissionDocument(
          submission.id,
          fileStorageId
        ),
      [submission.id]
    )
    const validationSchema = YupObject({
      comments: YupString().test(
        'text-required',
        t('submissionDetail.candidateDetails.form.comments.label'),
        (value) => {
          if (value) {
            const span = document.createElement('span')
            span.innerHTML = value
            const text = span.textContent ?? span.innerText
            if (text) {
              return true
            }
          }
          return false
        }
      ),
      dateAvailable: YupString().when('availability', {
        is: AvailabilityDefaults.DATE,
        then: YupString().required(
          t(
            'submissionDetail.candidateDetails.form.availability.requiredError1'
          )
        ),
      }),
      availability: YupString().required(
        t('submissionDetail.candidateDetails.form.availability.requiredError1')
      ),
      customAvailability: YupString().when('availability', {
        is: AvailabilityDefaults.OTHER,
        then: YupString()
          .required(
            t(
              'submissionDetail.candidateDetails.form.availability.requiredError2'
            )
          )
          .max(
            140,
            t(
              'submissionDetail.candidateDetails.form.availability.maxLengthError'
            )
          ),
      }),
      salary: YupString().when('employmentType', {
        is: EmploymentTypes.Permanent.toLowerCase(),
        then: YupString().required(
          t('submissionDetail.candidateDetails.form.salary.requiredError')
        ),
      }),
      billRate: YupString().when('employmentType', {
        is: (val: EmploymentTypes) =>
          val === EmploymentTypes.Contract.toLowerCase() ||
          val === EmploymentTypes.ContractToHire.toLowerCase(),
        then: YupString().required(
          t('submissionDetail.candidateDetails.form.billRate.requiredError')
        ),
      }),
    })

    const formikInstance = useFormik({
      validateOnMount: true,
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit: () => {},
    })

    const {
      dirty,
      errors,
      isSubmitting,
      touched,
      values,
      isValid,
      submitCount,
      handleSubmit,
      getFieldProps,
      setFieldValue,
      setFieldTouched,
      setSubmitting,
      submitForm,
    } = formikInstance

    const valuesRef = useRef(values)

    useEffect(() => {
      valuesRef.current = values
    }, [values])

    const savedTemplate = useMemo(
      () =>
        submission.candidate.submissionEmailTemplate?.emailTemplates.find(
          (template) => template.language === values.submissionLanguage
        ),
      [submission.candidate.submissionEmailTemplate?.emailTemplates, values]
    )

    const handleSave = useCallback((): void => {
      setIsSaving(true)
      handleSaveSubscriptionRef.current = submissionService
        .updateSubmission(values, isViewMode ? false : !!savedTemplate)
        .subscribe({
          complete: () => {
            setResetEmail?.(true)
            addAlert({
              message: t('submissionDetail.candidateDetails.alert.save'),
            })
            submissionStore.dispatch({
              isSaveConfirmationModalOpen: false,
              isCandidateCartOpen: false,
            })
          },
          error: () => {
            addAlert({
              severity: 'error',
              message: t('submissionDetail.candidateDetails.alert.failed'),
            })
          },
        })

      handleSaveSubscriptionRef.current.add(() => setIsSaving(false))
    }, [
      isViewMode,
      setResetEmail,
      addAlert,
      t,
      values,
      savedTemplate,
      handleSaveSubscriptionRef,
    ])

    const nextOrOpenModal = useCallback(() => {
      if (savedTemplate && dirty && !isViewMode) {
        setAction('next')
        setOpenModal?.(true)
      } else {
        nextHandler()
      }
    }, [nextHandler, setOpenModal, dirty, savedTemplate, isViewMode])

    const saveOrOpenModal = useCallback(() => {
      if (savedTemplate && !isViewMode) {
        setOpenModal?.(true)
        setAction('save')
      } else {
        handleSave()
      }
    }, [savedTemplate, setOpenModal, setAction, handleSave, isViewMode])

    const hasResumeError = !!(
      submitCount && !submission.candidate.resume?.fileStorageId
    )

    const handleModalConfirm = useCallback(() => {
      if (action === 'next') {
        nextHandler()
        setOpenModal?.(false)
      } else if (action === 'save') {
        handleSave()
        setOpenModal?.(false)
      }
    }, [action, nextHandler, setOpenModal, handleSave])

    const handleCloseModal = useCallback(() => {
      setOpenModal?.(false)
      openPreview?.()
    }, [openPreview, setOpenModal])

    useImperativeHandle(ref, () => ({
      handleModalConfirm,
      handleCloseModal,
    }))

    useEffect(() => {
      submissionService.submissionUpdateFormPropSubject.value = {
        dirty,
        errors,
        isSubmitting,
        touched,
        values,
        isValid,
        submitCount,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setFieldTouched,
        setSubmitting,
        submitForm,
      }
      return () => {
        submissionService.submissionUpdateFormPropSubject.value = {}
      }
    }, [
      values,
      isValid,
      dirty,
      isSubmitting,
      submitCount,
      setSubmitting,
      submitForm,
      errors,
      touched,
      handleSubmit,
      getFieldProps,
      setFieldValue,
      setFieldTouched,
    ])

    useEffect(() => {
      if (candidateHighlights || values.comments) {
        if (candidateHighlights !== values.comments) {
          from(
            setFieldValue('comments', candidateHighlights || values.comments)
          ).subscribe()
        }
      }
    }, [candidateHighlights, setFieldValue, values.comments])

    useEffect(() => {
      submissionStore.dispatch({
        unsavedChanges: dirty && isValid,
      })
    }, [dirty, isValid])

    useEffect(() => {
      if (submission.candidate.atsUserId && submission.atsJobId) {
        subscriptionRef.current = candidateAtsService
          .getCandidateFiles({
            atsCandidateId: Number(submission.candidate.atsUserId),
          })
          .subscribe({
            next: (resumeData) => {
              const dataArray = mapToDataArray(resumeData, 'name')
              setResumeOptions(dataArray)
              const remainingFiles = dataArray.filter(
                (file) => file.id !== resumeSelection
              )
              setAdditionalDocumentsOptions(remainingFiles)
            },
            error: handleError,
          })
      }
    }, [
      handleError,
      submission.atsJobId,
      submission.candidate.atsUserId,
      subscriptionRef,
      candidateAtsService,
      resumeSelection,
    ])

    const { track } = useTrackingWrapper()

    const handleGenerateCandidateHighlights = useCallback(() => {
      const payload: ICandidateHighlights = {
        atsJobId: submission.atsJobId,
        resumeFileStorageId: submission.candidate.resume?.fileStorageId ?? '',
        language: values.submissionLanguage ?? i18n.language,
      }
      if (submission.candidate.highlightsAdditionalDocuments?.length) {
        payload.additionalDocuments =
          submission.candidate.highlightsAdditionalDocuments.reduce(
            (acc, document) => {
              if (document.fileStorageId) {
                acc.push({
                  id: document.fileStorageId,
                  type: FileSource.FileUpload,
                })
              }
              return acc
            },
            [] as HighlightDocument[]
          )
      }
      subscriptionRef.current = jobAiService
        .generateCandidateHighlights(payload)
        .pipe(
          switchMap((highlights) =>
            submissionService.updateSubmission({
              ...valuesRef.current,
              comments: highlights,
            })
          )
        )
        .subscribe({
          error: () => {
            setIsBadResumeCall(true)
            addAlert({
              message: t('error.invalidResume'),
              severity: 'error',
            })
          },
          complete: () => {
            track({
              eventName: gtmEvents.GenerateAiCandidateHighlights,
              ...payload,
              featureLocation: AiFeatureLocations.Bullhorn,
              atsCandidateId: submission.candidate.atsUserId,
              jobId: submission.jobId,
            })
            // clear the candidateHighlights stream after setting it to the editor
            setTimeout(() => {
              jobAiService.setCandidateHighlightStream('')
            }, 100)
          },
        })
    }, [
      t,
      i18n.language,
      track,
      submission.atsJobId,
      submission.candidate.atsUserId,
      submission.candidate.resume?.fileStorageId,
      submission.candidate.highlightsAdditionalDocuments,
      submission.jobId,
      subscriptionRef,
      values,
      addAlert,
    ])

    const handleSelectResume = useCallback((newSelection) => {
      setResumeSelection(newSelection)
    }, [])

    return (
      <FormikProvider value={formikInstance}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          rowGap={3}
          sx={{ marginY: 3, pb: hideButtons ? '40px' : '0px' }}
        >
          {!isCartSubmission && (
            <Grid item>
              <FormControl>
                <SubLabel
                  id="submissionLanguage-label"
                  htmlFor="submissionLanguage"
                  required
                >
                  {t('submissionDetail.candidateDetails.form.language')}
                </SubLabel>
                <Select
                  fullWidth={false}
                  size="small"
                  sx={{
                    height: '44px',
                    backgroundColor: 'background.default',
                  }}
                  {...getFieldProps('submissionLanguage')}
                >
                  <MenuItem
                    key={PreferredLanguageType.EN}
                    value={PreferredLanguageType.EN}
                  >
                    <ListItemText
                      primary={t(
                        `common.jobGenerator.preferredLanguage.${PreferredLanguageType.EN}`
                      )}
                    />
                  </MenuItem>
                  <MenuItem
                    key={PreferredLanguageType.FR}
                    value={PreferredLanguageType.FR}
                  >
                    <ListItemText
                      primary={t(
                        `common.jobGenerator.preferredLanguage.${PreferredLanguageType.FR}`
                      )}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              focused={resumeFocused}
              error={hasResumeError}
              sx={{
                '.MuiSelect-select': { backgroundColor: 'background.default' },
              }}
            >
              <SubLabel htmlFor="resume" required>
                {t('submissionDetail.candidateDetails.form.resume.label')}
              </SubLabel>
              <SubmissionDetailFileSelector
                candidate={submission.candidate}
                submissionId={submission.id}
                resumeOptions={resumeOptions}
                uploadResume={uploadResume}
                hasResumeError={hasResumeError}
                deleteResume={deleteResume}
                onBlur={() => setResumeFocused(false)}
                onFocus={() => setResumeFocused(true)}
                onResumeChange={(isValidResponse) => {
                  setIsFirstLoad(false)
                  setIsBadResumeCall(!isValidResponse)
                }}
                isCartSubmission={isCartSubmission}
                resumeSelection={resumeSelection}
                handleSelectResume={handleSelectResume}
              />
              {hasResumeError && (
                <SubHelperText>
                  {t(
                    'submissionDetail.candidateDetails.form.resume.requiredError'
                  )}
                </SubHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <SubLabel>
                {t(
                  'submissionDetail.candidateDetails.form.additionalDocuments.label'
                )}
              </SubLabel>
              <DocumentUploader
                additionalDocumentsValue={
                  submission.candidate.additionalDocuments
                }
                uploadDocument={uploadAdditionalDocuments}
                deleteDocument={deleteDocument}
                limit={10}
                dropzoneStyles={{
                  borderRadius: '20px',
                }}
                onChange={(value) => {
                  submissionService.updateSubmissionAdditionalDocuments(
                    value,
                    null
                  )
                }}
                logToRollbar={(error: string) => rollbarInstance.error(error)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              error={!!(touched.comments && errors.comments)}
              variant="outlined"
              fullWidth
            >
              <Grid item container>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <SubLabel htmlFor="comments" required>
                    {t('submissionDetail.candidateDetails.form.comments.label')}
                  </SubLabel>
                  {isCartSubmission && (
                    <FormControl>
                      <Select
                        fullWidth={false}
                        size="small"
                        sx={{
                          height: '36.5px',
                          backgroundColor: 'background.default',
                          ml: 2,
                        }}
                        {...getFieldProps('submissionLanguage')}
                      >
                        <MenuItem
                          key={PreferredLanguageType.EN}
                          value={PreferredLanguageType.EN}
                        >
                          <ListItemText
                            primary={t(
                              `common.jobGenerator.preferredLanguage.${PreferredLanguageType.EN}`
                            )}
                          />
                        </MenuItem>
                        <MenuItem
                          key={PreferredLanguageType.FR}
                          value={PreferredLanguageType.FR}
                        >
                          <ListItemText
                            primary={t(
                              `common.jobGenerator.preferredLanguage.${PreferredLanguageType.FR}`
                            )}
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    item
                    display="flex"
                    justifyContent="end"
                    flexDirection="row"
                    flexWrap="nowrap"
                    xs={6}
                    mb={1}
                    ml="auto"
                  >
                    <Box
                      sx={{
                        width: '25px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CopilotIcon />
                    </Box>
                    <Box>
                      {(!isValidResume || isBadResumeCall) && (
                        <Tooltip
                          title={t('error.invalidResume')}
                          placement="top"
                        >
                          <IconButton>
                            <PriorityHighIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <LoadingButton
                      disabled={
                        submission.candidate.resume === null ||
                        !isValidResume ||
                        isBadResumeCall
                      }
                      variant="contained"
                      type="button"
                      onClick={handleGenerateCandidateHighlights}
                      loading={isLoading}
                      sx={{ minWidth: isLoading ? '250px' : 'initial' }}
                      loadingIndicator={
                        <LoadingIndicator
                          label={t('common.jobGenerator.btn.generating')}
                        />
                      }
                    >
                      {conditionHandler(
                        isLoading,
                        t('common.jobGenerator.btn.generating'),
                        t('btn.autoGenerate')
                      )}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  focused={additionalDocumentFocused}
                  error={isBadHighlightsFileCall}
                  sx={{
                    '.MuiSelect-select': {
                      backgroundColor: 'background.default',
                    },
                    mb: 3,
                  }}
                >
                  <SubLabel
                    id="highlights-file-select-label"
                    variant="filled"
                    sx={{ fontWeight: 400 }}
                  >
                    {t(
                      'submissionDetail.candidateDetails.form.highlights.fileSelect'
                    )}
                  </SubLabel>

                  <HighlightsFileSelect
                    candidate={submission.candidate}
                    submissionId={submission.id}
                    options={additionalDocumentsOptions}
                    onBlur={() => setAdditionalDocumentFocused(false)}
                    onFocus={() => setAdditionalDocumentFocused(true)}
                    labelId="highlights-file-select-label"
                    id="highlights-file-select"
                    onChange={(isValidFile) =>
                      setIsBadHighlightsFileCall(isValidFile)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={12}>
                <FormEditor
                  environment={environment}
                  name="comments"
                  height={480}
                  initialValue={submission?.candidate?.comments ?? ''}
                />
                {touched.comments && errors.comments && (
                  <SubHelperText>{errors.comments}</SubHelperText>
                )}
              </Grid>
            </FormControl>
          </Grid>

          <Grid item container columnSpacing={1} rowSpacing={2}>
            {values.employmentType.toLowerCase() ===
            EmploymentTypes.Permanent.toLowerCase() ? (
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  error={!!(touched.salary && errors.salary)}
                  variant="outlined"
                >
                  <SubLabel htmlFor="salary" required>
                    {t('submissionDetail.candidateDetails.form.salary.label')}{' '}
                    <span>
                      {t(
                        'submissionDetail.candidateDetails.form.salary.label2'
                      )}
                    </span>
                  </SubLabel>

                  <Field name="salary">
                    {({ field }: FieldProps) => (
                      <CompensationField
                        {...field}
                        type={CompensationFieldType.Salary}
                        sx={{ backgroundColor: 'background.default' }}
                        aria-describedby="salary-text"
                      />
                    )}
                  </Field>

                  {touched.salary && errors.salary && (
                    <SubHelperText id="salary-text">
                      {errors.salary}
                    </SubHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  error={!!(touched.billRate && errors.billRate)}
                  variant="outlined"
                >
                  <SubLabel htmlFor="billRate" required>
                    {t('submissionDetail.candidateDetails.form.billRate.label')}
                    <Box
                      sx={{
                        display: 'inline',
                        width: '59px',
                        position: 'absolute',
                        right: '26px',
                      }}
                    >
                      {t(
                        'submissionDetail.candidateDetails.form.billRate.labelPer'
                      )}
                    </Box>
                  </SubLabel>
                  <BillRateFormikField
                    name="billRate"
                    ratePeriodFieldName="ratePeriodType"
                    size="medium"
                    sx={{
                      backgroundColor: theme.palette.common.white,
                    }}
                  />

                  {touched.billRate && errors.billRate && (
                    <SubHelperText id="billRate-text">
                      {errors.billRate}
                    </SubHelperText>
                  )}
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <SubLabel id="currency-label">
                  {t('submissionDetail.candidateDetails.form.currency.label')}
                </SubLabel>
                <Select
                  labelId="currency-label"
                  {...getFieldProps('currency')}
                  sx={{ backgroundColor: 'background.default' }}
                >
                  {Object.values(CurrencyTypes).map((v, index) => (
                    <MenuItem key={index} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs md={4}>
              <FormControl
                fullWidth
                error={!!(touched.availability && errors.availability)}
              >
                <SubLabel id="dateAvailable-label" required>
                  {t(
                    'submissionDetail.candidateDetails.form.availability.label'
                  )}
                </SubLabel>

                <Field name="availability">
                  {({ field }: FieldProps) => (
                    <AvailabilityField
                      {...field}
                      size="medium"
                      sx={{ backgroundColor: 'background.default' }}
                    />
                  )}
                </Field>

                {touched.availability && errors.availability && (
                  <SubHelperText id="availability-text">
                    {errors.availability}
                  </SubHelperText>
                )}
              </FormControl>
            </Grid>
            {values.availability === AvailabilityDefaults.DATE && (
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', alignItems: 'end' }}
              >
                <FormControl
                  fullWidth
                  error={
                    !!(
                      touched.dateAvailable &&
                      errors.dateAvailable &&
                      !values.dateAvailable
                    )
                  }
                >
                  <AvailabilityDateField
                    name="dateAvailable"
                    inputSx={{ backgroundColor: 'background.default' }}
                  />

                  {touched.dateAvailable &&
                    errors.dateAvailable &&
                    !values.dateAvailable && (
                      <SubHelperText
                        id="dateAvailable-text"
                        sx={{ position: 'absolute', bottom: '-22px' }}
                      >
                        {errors.dateAvailable}
                      </SubHelperText>
                    )}
                </FormControl>
              </Grid>
            )}
            {values.availability === AvailabilityDefaults.OTHER && (
              <Grid
                item
                xs={12}
                md={8}
                sx={{ display: 'flex', alignItems: 'end' }}
              >
                <FormControl
                  fullWidth
                  error={
                    !!(touched.customAvailability && errors.customAvailability)
                  }
                >
                  <AvailabilityCustom
                    name="customAvailability"
                    sx={{ backgroundColor: 'background.default' }}
                  />

                  {touched.customAvailability && errors.customAvailability && (
                    <SubHelperText
                      id="availability-text"
                      sx={{ position: 'absolute', bottom: '-22px' }}
                    >
                      {errors.customAvailability}
                    </SubHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid item container>
            <Grid item xs={12} md={6}>
              <Field name="location">
                {({ field, meta }: any) => (
                  <LocationAutocomplete
                    {...field}
                    fullWidth
                    customLabel
                    label={
                      <SubLabel htmlFor="location">
                        {t('submissionDetail.candidateDetails.form.location')}
                      </SubLabel>
                    }
                    sx={{
                      backgroundColor: 'background.default',
                    }}
                    apiKey={environment.GOOGLE_MAPS_API_KEY}
                    error={!!(errors?.location && touched?.location)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <SubLabel htmlFor="workplaceType">
                  {t('submissionDetail.candidateDetails.form.workplace')}
                </SubLabel>
                <WorkplaceTypeField name="workplaceType" />
              </FormControl>
            </Grid>
          </Grid>

          {IS_ONE_PAGER_ENABLED && !isCartSubmission && (
            <Field name="isOnePagerEnabled">
              {({ field, form }: any) => (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={!!field.value}
                        onChange={(e) => {
                          form.setFieldValue(field.name, !!e.target.checked)
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography
                          variant="subtitle1Bold"
                          component="span"
                          sx={{ color: theme.palette.secondary.main }}
                        >
                          {t(
                            'submissionDetail.candidateDetails.form.onePagerCheckbox.new'
                          )}{' '}
                        </Typography>
                        <Typography variant="subtitle1Bold" component="span">
                          {t(
                            'submissionDetail.candidateDetails.form.onePagerCheckbox.label'
                          )}
                        </Typography>
                      </Box>
                    }
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      ml: '32px',
                      position: 'relative',
                      bottom: '8px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {t(
                      'submissionDetail.candidateDetails.form.onePagerCheckbox.content'
                    )}
                  </Typography>
                </Box>
              )}
            </Field>
          )}

          {!hideButtons ? (
            <Grid
              container
              item
              columnSpacing={1}
              justifyContent={{ xs: 'center', sm: 'center', md: 'right' }}
            >
              <Grid item xs={5} md={4}>
                <Button
                  fullWidth
                  disabled={isSubmitting || isSaving || !dirty || !isValid}
                  endIcon={isSaving && <CircularProgress size={18} />}
                  variant="text"
                  type="button"
                  onClick={saveOrOpenModal}
                >
                  {conditionHandler(
                    isSaving,
                    t('common.btn.saving'),
                    t('btn.saveProgress')
                  )}
                </Button>
              </Grid>
              <Grid item xs={5} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  type="button"
                  onClick={nextOrOpenModal}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={18} />}
                >
                  {t('btn.next')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                width: '100%',
                py: 1,
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                bottom: 0,
                left: 0,
                px: '24px',
                backgroundColor: 'white',
                zIndex: 3,
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              {backHandler && (
                <Button
                  onClick={backHandler}
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                >
                  {t('submissionList.multipleCandidates.previousCandidate')}
                </Button>
              )}

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: backHandler ? 'auto' : '100%',
                  justifyContent: backHandler ? 'initial' : 'space-between',
                }}
              >
                <Button
                  disabled={isSubmitting || isSaving || !dirty || !isValid}
                  endIcon={isSaving && <CircularProgress size={18} />}
                  variant="text"
                  type="button"
                  onClick={saveOrOpenModal}
                >
                  {conditionHandler(
                    isSaving,
                    t('common.btn.saving'),
                    t('btn.saveProgress')
                  )}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={nextOrOpenModal}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={18} />}
                >
                  {t('btn.next')}
                </Button>
              </Stack>
            </Box>
          )}
        </Grid>
        <SubmissionSaveConfirmation handleSave={saveOrOpenModal} />
      </FormikProvider>
    )
  }
)
