import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { SchoolOutlined } from '@mui/icons-material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { AccordionDetails, AccordionSummary, alpha } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { EducationHistory } from '@procom-labs/common'

import { SectionTitle, StyledAccordion } from '../common'
import { ExperienceAccordion } from './experience-accordion'

export const Education: FC<{
  educationHistory: EducationHistory[]
}> = memo(({ educationHistory }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <StyledAccordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon fontSize="large" />}
        sx={{
          padding: '0 8px 0 0',
          borderBottom: `2px solid ${alpha(theme.palette.divider, 0.3)}`,
        }}
      >
        <SectionTitle
          icon={
            <SchoolOutlined
              sx={{
                color: theme.palette.secondary.main,
                fontSize: '18px',
              }}
            />
          }
          title={t('organisms.candidateOnePager.education.title')}
        />
      </AccordionSummary>

      <AccordionDetails sx={{ px: 0 }}>
        <Timeline
          sx={{
            pl: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {educationHistory.map((history, index) => (
            <TimelineItem
              key={`${history.institution}-${index}`}
              sx={{
                ...(index === educationHistory.length - 1
                  ? { minHeight: 'auto' }
                  : {}),
              }}
            >
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  sx={{ width: '13px', height: '13px' }}
                />
                {index !== educationHistory.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <ExperienceAccordion
                  startDate={history.startDate}
                  endDate={history.endDate}
                  title={history.qualification}
                  subtitle={history.institution}
                  description={history.description}
                />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </AccordionDetails>
    </StyledAccordion>
  )
})
